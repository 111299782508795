import React, { useEffect, useRef, useState } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  showAlert,
  getAllReviews,
} from '../store/app/actions';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import WEIcon from '../components/WE-IconButton';
import { useTranslation } from 'react-i18next';
import http from '../services/http';
import Select from 'react-select';
import { isTabletOrMobile } from '../services/mediaQueries';
import { sleep } from '../services/utils';
import { Alert, Dialog } from '../components/Dialogs/SwalDialogs';
import { DynamicStars } from '../components/RatingStars';
import { ActiveButton } from '../components/WE-Button';
import search from '../assets/images/Search.svg';
import file from '../assets/images/file-export.svg';
import { Button } from 'react-bootstrap';
import next from '../assets/images/next.png';
import ViewReviewDetails from './ViewReviewDetails';
import moment from 'moment';
import agGridLocales from '../translations/ag-grid';
import Pagination from 'react-bootstrap/Pagination';
const initialState = {
  userId: null,
  fName: '',
  lName: '',
  phone: '',
  email: '',
  password: '',
  role: [],
  kitchensIds: [],
  employee: '',
  card: '',
  status: 'active',
};

const AllReviews = ({
  user,
  kitchen,
  setLayoutOptions,
  Roles,
  UserStatuses,
  Kitchens,
  useLoader,
  useFetchingLoader,
  MealDishStatuses,
  Languages,
  currentLanguage,
  getAllReviews,
  reviewsToDisplay,
  reviewsTotal,
  showFetchingLoader,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { orderId, date, mealTypeId } = useParams();
  const [usersToDisplay, setReivewsTodisplay] = useState(null);
  const [kitchensIds, setKitchensIds] = useState([]);
  const [payload, setPayload] = useState(initialState);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [update, setUpdate] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [prefetchData, setPrefetchData] = useState(null);
  const [rolesIds, setRolesIds] = useState();
  const [dummy, setDummy] = useState();
  const [copyData, setCopyData] = useState();
  const [resendData, setResendData] = useState();
  const [copied, setCopied] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [errors, setErrors] = useState({});
  const [tablecolumn, setTableColumn] = useState();
  const [viewDetails, setViewDetails] = useState();
  const [details, setDetails] = useState();

  const [currentPageAllReviews, setCurrentPageAllReviews] = useState(1); // Current page
  const [newPageSizeReviews, setPageSizeReviews] = useState(25); // Records per page
  const [totalReviewsRecords, setTotalReviewsRecords] = useState(); // Total records
  const [pageCountsReviews, setpageCountsReviews] = useState(1); // Total pages count
  const [searchKeyword, setSearchKeyword] = useState('');

  const UserStatusesMap = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status.id };
  }, {});
  const RolesMap = Roles.reduce((acc, role) => {
    return { ...acc, [role.value]: role.id };
  }, {});
  const MealDishStatusMap = MealDishStatuses.reduce((acc, mealDish) => {
    return { ...acc, [mealDish.value]: mealDish.id };
  }, {});
  /***********fetch User data */
  useEffect(() => {
    if (reviewsToDisplay) {
      setTotalReviewsRecords(reviewsToDisplay);
      setpageCountsReviews(Math.ceil(reviewsTotal / newPageSizeReviews));
    }
  }, [reviewsToDisplay]);
  const handleRecordCountChange = (elem) => {
    setCurrentPageAllReviews(1);
    setPageSizeReviews(elem?.target?.value);
  };
  const createSelectItems = (sel) => {
    let items = [];
    let pageNums = [5, 10, 25, 50, 100, 200, 500];
    for (let j = 0; j < 5; j++) {
      items.push(
        <option
          key={pageNums[j]}
          value={pageNums[j]}
          selected={pageNums[j] == sel}
        >
          {pageNums[j]}
        </option>
      );
    }
    return items;
  };
  /** Optimization, search and pagination with redux is inside this function */
  const fetchDataFromAPI = async () => {
    const filterPayload = {
      filter: { kitchenId: kitchen.id },
      page: currentPageAllReviews,
      limit: newPageSizeReviews,
      keyword: searchKeyword,
    };
    //let filterPayload = { kitchenId: kitchen?.id,  }
    await useFetchingLoader(async () => {
      await getAllReviews(filterPayload);
    });
  };
  useEffect(() => {
    const fetchData1 = async () => {
      let filter = {
        mealTypeId: mealTypeId,
      };
      const data = await useFetchingLoader(
        async () => await http.get(`review/getByMealId/${mealTypeId}`)
      );

      let filterData = data?.filter((el) => el.rating != null);
      setPrefetchData(filterData);
      let reviewsData = filterData?.map((u, i) => ({
        orderId: u?.id,
        // Name: u && u['user.firstName'],
        Name: u && [u.userfirstName, u.userlastName].join(' '),
        dishName: u?.dishName,
        Rating: u?.rating,
        description: u?.ratingText,
        date: new Date(u?.createdAt).toISOString().slice(0, 10),
        dishId: u?.id,
      }));
      setReivewsTodisplay(reviewsData);
    };
    const fetchData2 = async () => {
      await fetchDataFromAPI();
    };
    if (date && mealTypeId) {
      fetchData1();
    } else {
      fetchData2();
    }
  }, [dummy, kitchen?.id, currentPageAllReviews, newPageSizeReviews]);
  //, currentPageAllReviews, newPageSizeReviews, searchKeyword
  useEffect(() => {
    const fetchData1 = async () => {
      if (reviewsToDisplay?.length <= 0) return;
      let reviewsData = reviewsToDisplay?.map((u, i) => ({
        orderId: u.id,
        Name: [u.userfirstName, u.userlastName].join(' '),
        dishName: u,
        Rating: u?.mealDish ? u?.mealDish[0]?.rating : '',
        description: u?.mealDish ? u?.mealDish[0]?.ratingText : '',
        date: u?.mealDish
          ? new Date(u?.mealDish[0]?.createdAt).toISOString().slice(0, 10)
          : '',
        dishId: u?.mealDish ? u?.mealDish[0]?.id : '',
      }));
      setReivewsTodisplay(reviewsData);
    };
    fetchData1();
  }, [reviewsToDisplay]);
  /************global filter funcitionm */
  const onFilterTextBoxChanged = (e) => {
    const value = e.target.value;
    //gridApi.setQuickFilter(value);      // client side search AGGrid
    setCurrentPageAllReviews(1);
    setSearchKeyword(value); // Server side search on useEffect
  };

  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById('page-size').value;
    gridApi.paginationSetPageSize(Number(value));
  };

  const handleViewMore = (data) => {
    setViewDetails(!viewDetails);
    setDetails(data);
  };

  // ag grid data
  const columnDefs = [
    {
      field: 'Name',
      headerName: t('user'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      unSortIcon: true,
      flex: 2,
      autoHeight: true,
      wrapText: true,
      cellRenderer: (params) => {
        return `${params.data.Name}` && `${params.data.Name}` != ' '
          ? `${params.data.Name}`
          : '--';
      },
    },
    {
      field: 'dishName',
      headerName: t('dishName'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: 'DishName',
      unSortIcon: true,
      flex: 4,
      autoHeight: true,
      wrapText: true,
    },
    {
      field: 'Rating',
      headerName: t('rating'),
      cellRenderer: 'Rating',
      headerClass: 'resizable-header',
      suppressMovable: true,
      unSortIcon: true,
      sortable: true,
      filter: true,
      flex: 2,
      cellRenderer: (params) => {
        return `${params.data.Rating}` && `${params.data.Rating}` != ' '
          ? `${params.data.Rating}`
          : '--';
      },
    },
    {
      field: 'description',
      headerName: t('description'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      unSortIcon: true,
      flex: 4,
      autoHeight: true,
      wrapText: true,
      cellRenderer: (params) => {
        return `${params.data.description}` &&
          `${params.data.description}` != ' '
          ? `${params.data.description}`
          : '--';
      },
    },
    {
      field: 'date',
      headerName: t('date'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      unSortIcon: true,
      flex: 2,
      cellRenderer: (params) => {
        return `${params.data.date}` && `${params.data.date}` != ' '
          ? `${params.data.date}`
          : '--';
      },
    },
    {
      field: 'Action',
      headerName: t('action'),
      cellRenderer: 'ActionButton',
      headerClass: 'resizable-header',
      width: 150,
      suppressMovable: true,
      unSortIcon: true,
    },
  ];

  const columnDefsTab = [
    {
      field: 'Name',
      headerName: t('user'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      unSortIcon: true,
      flex: 2,
      autoHeight: true,
      wrapText: true,
      cellRenderer: (params) => {
        return `${params.data.Name}` && `${params.data.Name}` != ' '
          ? `${params.data.Name}`
          : '--';
      },
    },
    {
      field: 'dishName',
      headerName: t('dishName'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: 'DishName',
      unSortIcon: true,
      flex: 4,
      autoHeight: true,
      wrapText: true,
    },
    {
      field: 'description',
      headerName: t('description'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      unSortIcon: true,
      flex: 3,
      autoHeight: true,
      wrapText: true,
      cellRenderer: (params) => {
        return `${params.data.description}` &&
          `${params.data.description}` != ' '
          ? `${params.data.description}`
          : '--';
      },
    },
    {
      field: 'date',
      headerName: t('date'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      unSortIcon: true,
      flex: 2,
      cellRenderer: (params) => {
        return `${params.data.date}` && `${params.data.date}` != ' '
          ? `${params.data.date}`
          : '--';
      },
    },
    {
      field: 'Action',
      headerName: t('action'),
      cellRenderer: 'ActionButton',
      headerClass: 'resizable-header',
      width: 300,
      suppressMovable: true,
      unSortIcon: true,
      flex: 2,
    },
  ];

  const mobColumnDefs = [
    {
      field: 'Name',
      headerName: t('user'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      unSortIcon: true,
      widh: 100,
      flex: 3,
      autoHeight: true,
      wrapText: true,
      cellRenderer: (params) => {
        return `${params.data.Name}` && `${params.data.Name}` != ' '
          ? `${params.data.Name}`
          : '--';
      },
    },
    {
      field: 'dishName',
      headerName: t('dishName'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: 'DishName',
      unSortIcon: true,
      flex: 4,
      autoHeight: true,
      wrapText: true,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];

  const defaultColDef = {
    sortable: true,
    width: 'auto',
    resizable: true,
    filter: true,
    suppressMovable: true,
  };

  const gridOptions = {
    // rowHeight: 60,
    // groupHeaderHeight: 60,
    // headerHeight: 60,
    // rowStyle: {
    //   'border-bottom': 'white 10px solid',
    //   'border-top': 'white 0px solid',
    //   'border-radius': '5px',
    //   padding: '0px 5px',
    // },
  };

  /***********Export CSV file */
  const onBtnExport = () => {
    gridApi.exportDataAsCsv({
      fileName:
        'Review' + '-' + moment(new Date()).format('YYYY-MM-DD hh:mm A'),
    });
  };

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 479) {
      setTableColumn(columnDefsTab);
    } else if (window.innerWidth <= 480) {
      setTableColumn(mobColumnDefs);
    } else {
      setTableColumn(columnDefs);
    }
  }, []);

  const lang = localStorage.getItem('language');
  return (
    <div className="global-data-table review-management">
      <div className="container">
        <div className="add-employee-btn">
          <div className="search-input review-management-search-input">
            <input
              type="text"
              id="filter-text-box"
              placeholder={t('freeTextSearch')}
              onInput={onFilterTextBoxChanged}
            />
            <img src={search} alt="search" />
            <button onClick={fetchDataFromAPI} className="search-button">
              <img src={search} alt="search" />
            </button>
          </div>

          <div className="active-button">
            <div className="actions">
              <img src={file} alt="add" onClick={() => onBtnExport()} />
            </div>
          </div>
        </div>
        {showFetchingLoader ? (
          ''
        ) : (
          <div
            className="ag-theme-alpine"
            style={{ height: '100%', width: '100%' }}
          >
            <AgGridReact
              className="ag-theme-alpine"
              rowData={usersToDisplay}
              localeText={agGridLocales[i18n.language]}
              reactUi={lang === '"he"' ? false : true}
              enableRtl={lang === '"he"' ? true : false}
              columnDefs={tablecolumn}
              domLayout={'autoHeight'}
              gridOptions={gridOptions}
              rowSelection="multiple"
              onGridReady={(params) => {
                setGridApi(params.api);
                setColumnApi(params.columnApi);
              }}
              frameworkComponents={{
                ActionButton: (e) => (
                  <ActionButton history={history} e={e} user={user} t={t} />
                ),
                Rating: (e) => <Rating history={history} e={e} t={t} />,
                DishName: (e) => <DishName e={e} />,
                ViewMore: (e) => (
                  <ViewMore e={e} handleViewMore={handleViewMore} />
                ),
              }}
              debug={false}
              overlayLoadingTemplate={'<p></p>'}
              // rowSelection={'multiple'}
              // rowGroupPanelShow={'always'}
              // pivotPanelShow={'always'}
              // enableRangeSelection={true}
              // pagination={true}
              // paginationPageSize={10}
              // paginationNumberFormatter={function (params) {
              //   return '[' + params.value.toLocaleString() + ']';
              // }}
            ></AgGridReact>
            <div className="pagination-view we-pagination-view">
              <div className="we_show_records_main">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                    direction: 'ltr',
                    gap: 5,
                  }}
                >
                  <p>
                    {newPageSizeReviews * (currentPageAllReviews - 1) + 1} -{' '}
                    {newPageSizeReviews * currentPageAllReviews}
                  </p>
                  <p>{t('of')}</p>
                  <p>{reviewsTotal || 0}</p>
                  <p>{t('results')}</p>
                </div>
              </div>
              <Pagination
                style={{
                  display: 'flex',
                  flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                }}
              >
                <Pagination.First
                  disabled={pageCountsReviews <= 1 ? true : false}
                  onClick={() => {
                    setCurrentPageAllReviews(1);
                  }}
                  style={{
                    transform:
                      i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
                <Pagination.Prev
                  disabled={pageCountsReviews <= 1 ? true : false}
                  onClick={() => {
                    currentPageAllReviews - 1 > 0
                      ? setCurrentPageAllReviews(currentPageAllReviews - 1)
                      : '';
                  }}
                  style={{
                    transform:
                      i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />

                <Pagination.Item active>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection:
                        i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                      direction: 'ltr',
                      gap: 5,
                    }}
                  >
                    <p style={{ margin: 0 }}>{t('page')}</p>
                    <p style={{ margin: 0 }}>{currentPageAllReviews}</p>
                    <p style={{ margin: 0 }}>{t('of')}</p>
                    <p style={{ margin: 0 }}>
                      {reviewsTotal && newPageSizeReviews
                        ? Math.ceil(reviewsTotal / newPageSizeReviews)
                        : 0}
                    </p>
                  </div>
                </Pagination.Item>

                <Pagination.Next
                  disabled={pageCountsReviews <= 1 ? true : false}
                  onClick={() => {
                    currentPageAllReviews <
                    Math.ceil(reviewsTotal / newPageSizeReviews)
                      ? setCurrentPageAllReviews(currentPageAllReviews + 1)
                      : '';
                  }}
                  style={{
                    transform:
                      i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
                <Pagination.Last
                  disabled={pageCountsReviews <= 1 ? true : false}
                  onClick={() => {
                    setCurrentPageAllReviews(
                      Math.ceil(reviewsTotal / newPageSizeReviews)
                    );
                  }}
                  style={{
                    transform:
                      i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
              </Pagination>
            </div>

            <ViewReviewDetails
              open={viewDetails}
              details={details}
              handleClose={handleViewMore}
            />
            {/* <div className="pagination-header">
          {t('pageSize')}:
          <select onChange={() => onPageSizeChanged()} id="page-size">
            <option value="10" selected={true}>
              10
            </option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

const ActionButton = ({ history, e, user, t }) => {
  let mealId = e?.data?.dishId
    ? e?.data?.dishId
    : e?.data?.dishName?.mealDish[0].mealDish.dishId;
  return (
    <div className={`review-btn`}>
      <Button
        className="edit-btn"
        onClick={() => {
          history.push({
            pathname: `/reviewDetails/${e?.data?.orderId}/${mealId}`,
            state: { userdata: user },
          });
        }}
      >
        {t('viewMore')}
      </Button>
    </div>
  );
};

const Rating = ({ history, e, t }) => {
  return (
    <div className={`rating-star`} style={{ pointerEvents: 'none' }}>
      <DynamicStars value={e?.data?.Rating} />
    </div>
  );
};

const DishName = ({ e }) => {
  return (
    <div className="dish-name">
      {e?.data?.dishName?.mealDish
        ? e?.data?.dishName?.mealDish[0]?.mealName
        : ''}
      {/* {e?.data?.dishName?.mealDish?.dish?.name
        ? e?.data?.dishName?.mealDish?.dish?.name
        : ''}
      {e?.data?.dishName?.mealDish.length > 0 &&
        e?.data?.dishName?.mealDish?.map((el, i) => (
          <p key={i}>{el?.mealDish?.dish?.name}</p>
        ))} */}
    </div>
  );
};
const ViewMore = ({ e, handleViewMore }) => {
  return (
    <div className="view-more-btn">
      <img src={next} alt="next" onClick={() => handleViewMore(e.data)} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  user: state.user,
  Roles: state.app.enums.Role,
  UserStatuses: state.app.enums.UserStatus,
  Kitchens: state.kitchens.options,
  currentLanguage: state.languages.current,
  MealDishStatuses: state.app.enums.MealDishStatus,
  Languages: state.app.enums.Languages,
  CurrentLanguage: state.languages.current,
  reviewsToDisplay: state.app.reviewsToDisplay,
  reviewsTotal: state.app.reviewsTotal,
  showFetchingLoader: state.app.showFetchingLoader,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
  getAllReviews,
};
export default connect(mapStateToProps, mapDispatchToProps)(AllReviews);
