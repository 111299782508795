import React, { useEffect, useState, useRef } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  dishesData,
} from '../../store/app/actions';
import { connect } from 'react-redux';
import http from '../../services/http';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../services/mediaQueries';
import { StaticStars } from '../../components/RatingStars';
import { ActiveButton } from '../../components/WE-Button';
import WEIcon from '../../components/WE-IconButton';
import DishImage from '../../screens/client/DishImage';
import { useHistory } from 'react-router';
import enums from '../../services/enums';
import { sleep } from '../../services/utils';
import SearchLineWithButton from '../../components/SearchLineWithButton';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import { Alert, Dialog } from '../../components/Dialogs/SwalDialogs';
import Chip from '@material-ui/core/Chip';
import { useParams } from 'react-router';
import { Button, Form } from 'react-bootstrap';
import searchimg from '../../assets/images/Search.svg';
import AddEditDishPopup from './AddEditdishPopup';
import { initLanguages } from '../../store/languages/actions';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import next from '../../assets/images/next.png';
import ViewDishDetail from './ViewDishDetail';
import agGridLocales from '../../translations/ag-grid';
import Pagination from 'react-bootstrap/Pagination';
import cancel from '../../assets/images/close.svg';
import searchIcon from '../../assets/icons/search.png';
const DishManagement = ({
  setLayoutOptions,
  user,
  kitchen,
  DishStatuses,
  useLoader,
  useFetchingLoader,
  languages,
  DishCategories,
  currentLanguage,
  dishesData,
  dishesToDisplay,
  dishesTotal,
}) => {
  const InitialValues = {
    image: null,
    dishCategoryId: 1,
    translations: languages.map((lang) => {
      return { languageId: lang.id };
    }),
    price: 0,
  };
  const { t, i18n } = useTranslation();
  const { TagName } = useParams();
  const history = useHistory();
  const [dishes, setDishes] = useState([]);
  const [search, setSearch] = useState('');
  const [addEditPopup, setAddEditPopup] = useState(false);
  const [dish, setDish] = useState();
  const [translations, setTranslations] = useState({});
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [fileValue, setFileValue] = useState();
  const [errors, setErrors] = useState({});
  const [dummy, setDummy] = useState(false);
  const [values, setValues] = useState(InitialValues);
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [tableColumn, setTableColumn] = useState();
  const [viewDetails, setViewDetails] = useState();
  const [details, setDetails] = useState();
  const lang = localStorage.getItem('language');
  const [dishesToShow, setDishesToShow] = useState(null);
  const [newPageSizeDishes, setPageSizeDishes] = useState(25); // Records per page
  const [totalDisheRecords, setTotalDisheRecords] = useState(0); // Total records
  const [pageCountsDishes, setpageCountsDishes] = useState(1); // Total pages count
  const [currentPageDishes, setCurrentPageDishes] = useState(1); // Current page
  const [searchKeyword, setSearchKeywordDishes] = useState(''); // Search text
  const [dishCategory, setDishCaregory] = useState(0);
  const [columnAndOrder, setColumnAndOrder] = useState({});
  const [onlyHistory, setOnlyHistory] = useState(true);
  // useEffect(async () => await useLoader(() => sleep()), []);
  const DishStatusMap = DishStatuses.reduce((acc, dish) => {
    return { ...acc, [dish.value]: dish.id };
  }, {});
  const handleReset = () => {
    setSearchKeywordDishes('');
    setColumnAndOrder({});
    setDishCaregory(0);
    setCurrentPageDishes(1);
  };
  useEffect(() => {
    if (TagName) {
      setSearch(TagName);
    } else {
      setSearch('');
    }
  }, [window.location, TagName]);

  // const fetchDishes = async () => {

  // };

  const fetchDataFromAPI = async () => {
    if (!kitchen) return;
    setLayoutOptions({ title: `${t('dishManagement')} ${kitchen.name}` });
    let filterPayload = {
      kitchenId: kitchen.id,
      dishStatusId: DishStatusMap.active,
      page: currentPageDishes,
      limit: newPageSizeDishes,
      dishCategoryId: dishCategory == 0 ? undefined : dishCategory,
      findStr: searchKeyword === '' ? undefined : searchKeyword,
      orderField:
        'field' in columnAndOrder
          ? columnAndOrder?.field
          : 'dish|name|translation.text',
      orderTarget: 'order' in columnAndOrder ? columnAndOrder?.order : 'asc',
    };
    Object.keys(filterPayload).forEach((key) => {
      if (filterPayload[key] === undefined) {
        delete filterPayload[key];
      }
    });
    await useFetchingLoader(async () => {
      await dishesData(filterPayload);
    });
  };
  useEffect(() => {
    if (dishesToDisplay) {
      let serverDishes = dishesToDisplay.map((u, i) => ({
        dishId: u.id,
        dishName: u.name,
        dishImage: u?.thumbnail ? u?.thumbnail : u?.image,
        dishPrice: u.price,
        dishRating: u.rating,
        numberOfRaters: u.numOfRaters,
        dishDescription: u.description,
        dishCategory: enums.display('DishCategory', u.dishCategoryId),
      }));
      setDishesToShow(serverDishes);
      setpageCountsDishes(Math.ceil(dishesTotal / newPageSizeDishes));
      setTotalDisheRecords(dishesTotal);
    }
  }, [dishesToDisplay]);
  const updateData = () => {
    setFileValue();
    setValues(InitialValues);
    setTranslations({});
    setAddEditPopup(!addEditPopup);
    setDish(null);
    fetchDataFromAPI();
  };
  useEffect(() => {
    const fetchData1 = async () => {
      await fetchDataFromAPI();
    };
    fetchData1();
  }, [
    kitchen,
    dummy,
    newPageSizeDishes,
    currentPageDishes,
    columnAndOrder,
    dishCategory,
  ]);

  const handleAddEdit = async (fl = false) => {
    if (fl) {
      setDummy((prev) => !prev);
    }
    setFileValue();
    setValues(InitialValues);
    setTranslations({});
    setAddEditPopup(!addEditPopup);
    setDish(null);
  };

  const handleEditdish = async (dishId, value) => {
    setDish(dishId);
    if (value) {
      setViewDetails(!viewDetails);
    }
    setAddEditPopup(!addEditPopup);
  };

  const handleDeleteClick = async (dishId, dishName) => {
    Dialog({
      title: t('removeDishTitle'),
      text: `${t('areYouSureYouWantToRemoveDish')} ${dishName}`,
      buttons: {
        confirmButtonText: t('removeDishBtnLabel'),
        cancelButtonText: t('back'),
      },
      onConfirm: async () => await DishesCallBacks.delete(dishId),
    });
  };

  const handleDeleteDish = async (dishId) => {
    try {
      await useFetchingLoader(async () => {
        const update = await http.put(`dishes/deleteDish/${dishId}`, {
          dishStatusId: DishStatusMap.deleted,
        });
        setViewDetails(false);
      });
      Dialog({
        title: 'Deleted Successfully!',
        type: 'success',
        buttons: {
          showCancelButton: false,
          confirmButtonText: t('confirm'),
        },
        onConfirm: async () => {
          setCurrentPageDishes((prev) => {
            if (prev == 1) {
              setDummy((prev) => !prev);
              return prev;
            } else {
              return 1;
            }
          });
        },
        onClose: () => {
          setCurrentPageDishes((prev) => {
            if (prev == 1) {
              setDummy((prev) => !prev);
              return prev;
            } else {
              return 1;
            }
          });
        },
      });
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  // ag grid data
  const columnDefs = [
    {
      field: 'dish|name|translation.text',
      headerName: t('name'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      // pinned: 'left',
      cellRenderer: 'NameCellRender',
      flex: 3,
    },
    {
      field: 'dish|description|translation.text',
      headerName: t('description'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      // pinned: 'left',
      cellRenderer: 'DishDescriptionNew',
      flex: 3,
    },
    {
      field: 'dishCategoryId',
      headerName: t('category'),
      suppressMovable: true,
      sortable: true,
      comparator: () => {},
      cellRenderer: (params) => {
        return `${params.data.dishCategory}`
          ? `${params.data.dishCategory}`
          : '--';
      },
    },
    {
      field: 'price',
      headerName: t('price'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.dishPrice}` ? `${params.data.dishPrice}` : '--';
      },
    },
    {
      field: 'numOfRaters',
      headerName: t('rating'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      cellRenderer: 'RatingStar',
    },
    {
      // field: 'Action',
      headerName: t('action'),
      headerClass: 'resizable-header',
      // width: 100,
      suppressMovable: true,
      cellRenderer: 'EditDeleteCellRender',
      hide: user.roleId === 9 || user.roleId === 10,
    },
  ];

  /**** tab view column */
  const columnDefsTab = [
    {
      field: 'dish|name|translation.text',
      headerName: t('name'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      // pinned: 'left',
      cellRenderer: 'NameCellRender',
      flex: 3,
    },
    {
      field: 'dishCategoryId',
      headerName: t('category'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.dishCategory}`
          ? `${params.data.dishCategory}`
          : '--';
      },
    },
    {
      field: 'price',
      headerName: t('price'),
      suppressMovable: true,
      sortable: true,
      comparator: () => {},
      width: 100,
      cellRenderer: (params) => {
        return `${params.data.dishPrice}` ? `${params.data.dishPrice}` : '--';
      },
    },

    {
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 50,
    },
  ];

  /**** mobile view column */
  const columnDefsMob = [
    {
      field: 'dish|name|translation.text',
      headerName: t('name'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      cellRenderer: 'NameCellRender',
      flex: 2,
    },
    {
      field: 'dishCategoryId',
      headerName: t('category'),
      sortable: true,
      comparator: () => {},
      suppressMovable: true,
      flex: 1,
      cellRenderer: (params) => {
        return `${params.data.dishCategory}`
          ? `${params.data.dishCategory}`
          : '--';
      },
    },
    {
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 50,
    },
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    suppressMovable: true,
  };

  const gridOptions = {
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };

  const DishesCallBacks = {
    addEdit: handleEditdish,
    delete: handleDeleteDish,
  };

  const handleViewMore = (data) => {
    setViewDetails(!viewDetails);
    setOnlyHistory(false);
    setDetails(data);
  };

  useEffect(() => {
    if (window.innerWidth <= 1199 && window.innerWidth >= 479) {
      setTableColumn(columnDefsTab);
    } else if (window.innerWidth <= 480) {
      setTableColumn(columnDefsMob);
    } else {
      setTableColumn(columnDefs);
    }
  }, []);
  const printSortStateToConsole = () => {
    const sortState = gridApi.getSortModel();

    if (sortState.length === 0) {
      setColumnAndOrder({});
    } else {
      setColumnAndOrder({
        field: sortState[0].colId,
        order: sortState[0].sort,
      });
    }
  };

  return (
    <div className={'dish-management-page global-data-table'}>
      <div className="container">
        <div className="top-header-section">
          <div className="search-section">
            <div className="from-date ml-0">
              <select
                name="meal_type"
                value={dishCategory}
                onChange={(e) => {
                  setDishCaregory(e.target.value);
                  setCurrentPageDishes(1);
                }}
              >
                <option value={0}>{t('dishCategory')}</option>
                {DishCategories.map((el, i) => (
                  <option value={el.id} key={i}>
                    {el.display}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex">
              <div className="dish-search-input">
                <img src={searchIcon} alt="search" />
                <input
                  type="text"
                  id="filter-text-box"
                  placeholder={t('freeTextSearch')}
                  value={searchKeyword}
                  onInput={(e) => {
                    setSearchKeywordDishes(e.target.value);
                  }}
                />
              </div>

              <div className="search-input searchBtn flex-container">
                <button
                  onClick={() => {
                    if (currentPageDishes == 1) {
                      fetchDataFromAPI();
                    } else {
                      setCurrentPageDishes(1);
                    }
                  }}
                  className="search-button margin-t-0"
                >
                  <img
                    src={searchIcon}
                    alt="search"
                    className="dish-button-search-img"
                  />
                </button>
                <button
                  onClick={() => {
                    handleReset();
                  }}
                  className="search-button reset-button margin-t-0"
                >
                  <img src={cancel} alt="search" className="dish-button-img" />
                </button>
              </div>
            </div>
          </div>

          {user.roleId !== 9 && user.roleId !== 10 && kitchen?.id && (
            <div className="add-dish-btn">
              <Button onClick={() => handleAddEdit(null)}>
                <i class="las la-plus"></i> <span>{t('addDish')}</span>
              </Button>
            </div>
          )}
        </div>
        {dishesToShow && dishesToShow?.length >= 0 ? (
          <>
            <AgGridReact
              className="ag-theme-alpine"
              rowData={dishesToShow}
              columnDefs={tableColumn}
              localeText={agGridLocales[i18n.language]}
              reactUi={lang === '"he"' ? false : true}
              enableRtl={lang === '"he"' ? true : false}
              onSortChanged={() => printSortStateToConsole()}
              frameworkComponents={{
                NameCellRender: (e) => <NameCellRender e={e} />,
                DishDescriptionNew: (e) => <DishDescriptionNew e={e} />,
                EditDeleteCellRender: (e) => (
                  <EditDeleteCellRender
                    e={e}
                    handleEditdish={handleEditdish}
                    handleDeleteClick={handleDeleteClick}
                    setOnlyHistory={setOnlyHistory}
                    setViewDetails={setViewDetails}
                    setDetails={setDetails}
                  />
                ),
                ViewMore: (e) => (
                  <ViewMore e={e} handleViewMore={handleViewMore} />
                ),
                RatingStar: (e) => <RatingStar e={e} />,
              }}
              domLayout={'autoHeight'}
              gridOptions={gridOptions}
              onGridReady={(params) => {
                setGridApi(params.api);
                setColumnApi(params.columnApi);
              }}
              debug={false}
              overlayLoadingTemplate={'<p></p>'}
              rowSelection={'multiple'}
              rowGroupPanelShow={'always'}
              pivotPanelShow={'always'}
              enableRangeSelection={true}
              pagination={false}
              // paginationNumberFormatter={function (params) {
              //   return '[' + params.value.toLocaleString() + ']';
              // }}
            ></AgGridReact>
            <div className="pagination-view we-pagination-view">
              <div className="we_show_records_main">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                    direction: 'ltr',
                    gap: 5,
                  }}
                >
                  <p>
                    {newPageSizeDishes * (currentPageDishes - 1) + 1} -{' '}
                    {newPageSizeDishes * currentPageDishes}
                  </p>
                  <p>{t('of')}</p>
                  <p>{totalDisheRecords}</p>
                  <p>{t('results')}</p>
                </div>
              </div>
              <Pagination
                style={{
                  display: 'flex',
                  flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                }}
              >
                <Pagination.First
                  disabled={pageCountsDishes <= 1 ? true : false}
                  onClick={() => {
                    setCurrentPageDishes(1);
                  }}
                  style={{
                    transform:
                      i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
                <Pagination.Prev
                  disabled={pageCountsDishes <= 1 ? true : false}
                  onClick={() => {
                    currentPageDishes > 1
                      ? setCurrentPageDishes(currentPageDishes - 1)
                      : '';
                  }}
                  style={{
                    transform:
                      i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />

                <Pagination.Item active>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection:
                        i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                      direction: 'ltr',
                      gap: 5,
                    }}
                  >
                    <p style={{ margin: 0 }}>{t('page')}</p>
                    <p style={{ margin: 0 }}>{currentPageDishes}</p>
                    <p style={{ margin: 0 }}>{t('of')}</p>
                    <p style={{ margin: 0 }}>
                      {Math.ceil(totalDisheRecords / newPageSizeDishes)}
                    </p>
                  </div>
                </Pagination.Item>

                <Pagination.Next
                  disabled={pageCountsDishes <= 1 ? true : false}
                  onClick={() => {
                    currentPageDishes <
                    Math.ceil(totalDisheRecords / newPageSizeDishes)
                      ? setCurrentPageDishes(currentPageDishes + 1)
                      : '';
                  }}
                  style={{
                    transform:
                      i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
                <Pagination.Last
                  disabled={pageCountsDishes <= 1 ? true : false}
                  onClick={() => {
                    setCurrentPageDishes(
                      Math.ceil(totalDisheRecords / newPageSizeDishes)
                    );
                  }}
                  style={{
                    transform:
                      i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
              </Pagination>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
      <AddEditDishPopup
        show={addEditPopup}
        handleClose={handleAddEdit}
        DishStatuses={DishStatuses}
        languages={languages}
        DishCategories={DishCategories}
        kitchen={kitchen}
        dish={dish}
        setAddEditPopup={setAddEditPopup}
        translations={translations}
        setTranslations={setTranslations}
        tags={tags}
        setTags={setTags}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        errors={errors}
        setErrors={setErrors}
        fileValue={fileValue}
        setFileValue={setFileValue}
        setValues={setValues}
        values={values}
        InitialValues={InitialValues}
        setDummy={setDummy}
        updateData={updateData}
      />
      <ViewDishDetail
        open={viewDetails}
        details={details}
        handleClose={handleViewMore}
        handleEditdish={handleEditdish}
        handleDeleteClick={handleDeleteClick}
        user={user}
        DishCategories={DishCategories}
        onlyHistory={onlyHistory}
      />
    </div>
  );
};

const ViewMore = ({ e, handleViewMore }) => {
  return (
    <div className="view-more-btn">
      <img src={next} alt="next" onClick={() => handleViewMore(e.data)} />
    </div>
  );
};

const NameCellRender = ({ e }) => {
  return (
    <section className={'image'}>
      <DishImage fileName={e.data.dishImage} />
      <div className="dish-name">
        <p>{e.data.dishName}</p>
      </div>
    </section>
  );
};
const DishDescriptionNew = ({ e }) => {
  return (
    <section className={'dish-description-one'}>
      <p className="one-line-desc">{e.data.dishDescription}</p>
    </section>
  );
};

const EditDeleteCellRender = ({
  e,
  handleEditdish,
  handleDeleteClick,
  setViewDetails,
  setOnlyHistory,
  setDetails,
}) => {
  return (
    <section>
      <div className={'actions'}>
        <i
          style={{ cursor: 'pointer' }}
          class="las la-pen"
          onClick={() => handleEditdish(e.data.dishId, false)}
        ></i>
        <i
          style={{ cursor: 'pointer' }}
          class="las la-trash"
          onClick={() => handleDeleteClick(e.data.dishId, e.data.dishName)}
        ></i>
        <i
          style={{ cursor: 'pointer' }}
          class="las la-book"
          onClick={() => {
            setDetails(e.data);
            setOnlyHistory(true);
            setViewDetails(true);
          }}
        ></i>
      </div>
    </section>
  );
};

const RatingStar = ({ e }) => {
  return (
    <section className={'section2'}>
      <div className={'rating'}>
        <StaticStars
          rating={e.data.dishRating || -1}
          numOfRaters={e.data.numberOfRaters}
        />
      </div>
    </section>
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
  kitchen: state.kitchens.current,
  DishStatuses: state.app.enums.DishStatus,
  languages: state.languages.options,
  DishCategories: state.app.enums.DishCategory,
  enums: state.app.enums,
  currentLanguage: state.languages.current,
  dishesToDisplay: state.app.dishesToDisplay,
  dishesTotal: state.app.dishesTotal,
});
const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  dishesData,
};
export default connect(mapStateToProps, mapDispatchToProps)(DishManagement);
