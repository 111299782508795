import React, { useEffect, useState } from 'react';
import '../../style/yourdish.css';
import { Button } from 'react-bootstrap';
import plusIcon from '../../assets/newimages/plus-icon.svg';
import minusIcon from '../../assets/newimages/minus-icon.svg';
import arrowRight from '../../assets/newimages/arrow-right-white.svg';
import defaultImage from '../../assets/images/defaultImage.png';

const MyOrderExtraItem = ({
  img,
  title,
  subText,
  price,
  multipleDish,
  data,
  mealDishArray,
  index,
  setMealDishArray,
  quant,
  category,
  setUpdate,
  orderId,
  removeOrder,
  extraDish,
  paymentOption,
}) => {
  const [quantity, setQuantity] = useState(quant ? quant : 1);
  const [pricese, setPrice] = useState();

  const handleIncrease = () => {
    const prev = [...mealDishArray];
    prev[index].quantity = prev[index]?.quantity
      ? prev[index]?.quantity + 1
      : 2;

    prev[index].price = price;
    setQuantity(quantity + 1);
    setMealDishArray(mealDishArray);
    setUpdate(true);
  };
  const handleDecrease = () => {
    const prev = [...mealDishArray];
    prev[index].quantity = prev[index]?.quantity
      ? prev[index]?.quantity - 1
      : 2;
    prev[index].price = price;
    setQuantity(quantity - 1);
    setMealDishArray(mealDishArray);
    setUpdate(true);
  };
  return (
    <div className="myorder-item" key={data?.id}>
      <img
        src={
          img
            ? img == 'h' || img === 'null'
              ? defaultImage
              : img
            : defaultImage
        }
        alt=""
        className="myorder-img"
      />
      <div className="myorder-info-section">
        <div className="myorder-info">
          <div className="order-dish-name">
            <h4>{title}</h4>
          </div>
          <span>{subText}</span>
          <div class="dishInfo-inner">
            <div class="meat-meat">
              <span>{category}</span>
            </div>
            <div class="meat-prices">
              <span>
                {paymentOption == 5 ? '' : '₪'}
                {price}
              </span>
            </div>
          </div>
        </div>
        <div className="myorder-price-section">
          <div className="myorder-quantity">
            {multipleDish ? (
              <>
                <Button
                  onClick={handleDecrease}
                  className={`remove-quantity ${
                    quantity === 1 ? 'disable-value' : ''
                  }`}
                >
                  <img src={minusIcon} alt="" />
                </Button>
                <span>{quantity}</span>
                <Button onClick={handleIncrease}>
                  <img src={plusIcon} alt="" />
                </Button>
              </>
            ) : (
              ''
            )}
          </div>
          <h6>
            {paymentOption == 5 ? '' : '₪'}
            {price * quantity}
          </h6>
        </div>
      </div>
    </div>
  );
};

export default MyOrderExtraItem;
