import React, { useState, useMemo, useEffect, useCallback } from 'react';
import '../../style/yourdish.css';
import { Modal, Button } from 'react-bootstrap';
import plusIcon from '../../assets/newimages/plus-icon.svg';
import minusIcon from '../../assets/newimages/minus-icon.svg';
import arrowRight from '../../assets/newimages/arrow-right-white.svg';
import order1 from '../../assets/newimages/order-img-1.png';
import order2 from '../../assets/newimages/order-img-2.png';
import order3 from '../../assets/newimages/order-img-3.png';
import WEIcon from '../../components/WE-IconButton';
import MyOrderItem from './MyOrderItem';
import MyOrderExtraItem from './MyOrderExtraItem';
import { Alert } from '../../components/Dialogs/SwalDialogs';

const AddExtrasPopup = ({
  extrasMeal,
  extrasData,
  totalPrice,
  extrasPrice,
  setExtrasPrice,
  handleExtrasMeal,
  setAddedExtras,
  addedExtras,
  setIsSkip,
  setValue,
  setTotalPrice,
  skipAddOrder,
  isSkip,
  AddOrderNew,
  t,
  paymentOption,
}) => {
  if (extrasData?.length > 0) {
    let sum = extrasData?.reduce((x, y) => x + y?.price, 0);
    setExtrasPrice(sum);
  }
  const [isNotAllowMoreThanFive, setIsNotAllowMoreThanFive] = useState(false);
  useEffect(() => {
    console.log('addedExtras useEffect', addedExtras);
    if (addedExtras.length > 4) {
      setIsNotAllowMoreThanFive(true);
    } else {
      setIsNotAllowMoreThanFive(false);
    }
  }, [addedExtras]);
  const handleaddedExtras = (extrasId) => {
    console.log('isNotAllowMoreThanFive', isNotAllowMoreThanFive);

    if (isNotAllowMoreThanFive) {
      console.log('addedExtras.length', addedExtras.length);
      Alert({ error: t('notMoreThanFive') });
    } else {
      const checkIfIdExits = addedExtras.find((el) => el === extrasId);
      if (checkIfIdExits) {
        return;
      }
      setValue(extrasId);
      setAddedExtras((prev) => {
        const prevArr = [...prev];
        prevArr.push(extrasId);
        setValue(extrasId);
        return prevArr;
      });
      const findExtra = extrasData.find((el) => el.id === extrasId);

      setTotalPrice(totalPrice + findExtra.price);
    }
  };

  const removeExtrasId = (extrasId) => {
    if (!addedExtras.length) {
      return;
    }
    const findExtraIdIndex = addedExtras.findIndex((el) => el === extrasId);
    if (findExtraIdIndex === -1) {
      return;
    }
    const findExtra = extrasData.find((el) => el.id === extrasId);

    setAddedExtras((prev) => {
      const prevArr = [...prev];
      prevArr.splice(findExtraIdIndex, 1);
      return prevArr;
    });
    setTotalPrice(totalPrice - findExtra.price);
  };
  return (
    <Modal
      show={extrasMeal}
      onHide={handleExtrasMeal}
      className="add-extras-popup"
      centered
    >
      <Modal.Header>
        <Modal.Title>{t('availableExtras')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="myorder-items-info">
          <div className="order-items-list">
            {extrasData?.length &&
              extrasData?.map((el) => (
                <div className="extras-listing">
                  <MyOrderExtraItem
                    img={el.imageUrl}
                    title={el.text}
                    subText="Secondary text..."
                    price={el.price}
                    extraDish={true}
                    paymentOption={paymentOption}
                  />
                  <ExtraDishIcon
                    arr={addedExtras && addedExtras}
                    id={el.id}
                    addedExtras={addedExtras}
                    handleaddedExtras={handleaddedExtras}
                    removeExtrasId={removeExtrasId}
                  />
                </div>
              ))}
          </div>
          <div className="myorder-amt-section">
            <p>{t('totalAmount')}</p>
            <h5>
              {' '}
              {paymentOption == 5 ? '' : '₪'}
              {totalPrice}
            </h5>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {addedExtras?.length > 0 ? (
          <Button className="continue-btn" onClick={AddOrderNew}>
            {t('continue')} <img src={arrowRight} alt="icon" />
          </Button>
        ) : (
          ''
        )}
        {addedExtras?.length > 0 ? (
          ''
        ) : (
          <Button className="skip-btn" onClick={skipAddOrder}>
            {t('skip')}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AddExtrasPopup;

const ExtraDishIcon = ({ id, handleaddedExtras, arr, removeExtrasId }) => {
  const show = useMemo(() => {
    if (arr.find((el) => el === id)) {
      return true;
    }
    return false;
  }, [arr]);

  return (
    <div className={`inventory`}>
      {!show ? (
        <WEIcon
          icon={'plus'}
          iconSize={'small'}
          backgroundColor={'green'}
          cb={() => handleaddedExtras(id)}
          isDisabled={show}
        />
      ) : (
        <WEIcon
          icon={'minus'}
          iconSize={'small'}
          backgroundColor={'red'}
          cb={() => removeExtrasId(id)}
          isDisabled={!show}
        />
      )}
    </div>
  );
};
