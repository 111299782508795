import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { connect } from 'react-redux';
import moment from 'moment';
import http from '../../services/http';
import { useTranslation } from 'react-i18next';
import dates from '../../services/dates';
import { getAssetUrl } from '../../services/aws';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  getWalletAmountFromApi,
  getReceipts,
} from '../../store/app/actions';
import { StaticStars } from '../../components/RatingStars';
import { useHistory } from 'react-router-dom';
import { isMobile } from '../../services/mediaQueries';
import agGridLocales from '../../translations/ag-grid';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import enums from '../../services/enums';
import DishImage from './DishImage';
import { sleep } from '../../services/utils';
import ShowPreviousContainer from '../../components/ShowPrevious';
import WEIcon from '../../components/WE-IconButton';
import * as SwalDialogs from '../../components/Dialogs/SwalDialogs';
import { Dialog, Alert } from '../../components/Dialogs/SwalDialogs';
import { sortOrders } from '../../services/sortItems';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import cancel from '../../assets/images/close.svg';
import TableBtn from '../Newfolder/TableBtn';
import edit from '../../assets/icons/pencil.png';
import cancelImg from '../../assets/icons/cancel.png';
import cibusImg from '../../assets/icons/cibus.png';
import voucher from '../../assets/icons/voucher.png';
import arrow from '../../assets/icons/angle-right-black.svg';
import reviewImg from '../../assets/icons/review.png';
import docImg from '../../assets/icons/icon-docs.svg';
import MyOrdersDetailsMobilePopup from '../Newfolder/MyOrdersDetailsMobilePopup';
import { getPaymentPreferences } from '../../store/kitchens/actions';
import CibusPaymentSuccessPopup from '../Newfolder/CibusPaymentSuccessPopup';
import CibusPaymentFailurePopup from '../Newfolder/CibusPaymentFailurePopup';
import { useLocation } from 'react-router-dom';
import { ActiveButton } from '../../components/WE-Button';
import { getEmployeeLobbyData } from '../../store/kitchens/actions';
import Pagination from 'react-bootstrap/Pagination';
import Tooltip from '@mui/material/Tooltip';
import i18n from '../../services/i18n';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '25px 35px',
  p: 6,
  borderRadius: '5px',
  maxWidth: '550px',
  width: '100%',
  minHeight: '300px',
};
// @ts-ignore
const MyMeals = ({
  user,
  kitchen,
  kitchens,
  MealTypes,
  OrderStatuses,
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  getPaymentPreferences,
  getEmployeeLobbyData,
  getWalletAmountFromApi,
  getReceipts,
  receipts,
}) => {
  const history = useHistory();
  const [orders, setOrders] = useState(null);
  const { t, i18n } = useTranslation();
  const [paymentPopup, setPaymentPopup] = useState(false);
  const [orderId, setOrderId] = useState();
  const [viewOrderData, setViewOrderData] = useState();
  const [mobOderData, setMobOrderData] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [paymentKitchenId, setPaymentKitchenId] = useState();
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [myOrdersMob, setMyOrdersMob] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [refresh, setRefresh] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [currentPageAllLogs, setcurrentPageAllLogs] = useState(1); // Current page
  const [newPageSizeLogs] = useState(25); // Records per page
  const [pageCountsLogs, setpageCountsLogs] = useState(1); // Total pages count
  const [reservationType, setReservationType] = useState('Reservations');
  const [totalOrders, setTotalOrders] = useState(0);
  const [columnAndOrder, setColumnAndOrder] = useState({});
  const [tableColumn, setTableColumn] = useState();
  const [gridApi, setGridApi] = useState();
  const [, setColumnApi] = useState();
  const [reservationStatus, setReservationStatus] = useState('all');
  // const [showPrevious, setShowPrevious] = useState(false);
  const { search } = useLocation();
  const lang =
    localStorage.getItem('language') ?? localStorage.getItem('defaultLang');
  const langId =
    localStorage.getItem('languageId') ??
    enums.enums.Languages.find(
      (el) => el.value == localStorage.getItem('defaultLang')
    ).id;
  const MealTypesMap = MealTypes.reduce((acc, type) => {
    return { ...acc, [type.id]: type };
  }, {});

  useEffect(() => {
    const fetchData1 = async () => {
      await useLoader(() => sleep());
    };
    fetchData1();
  }, []);

  const handleMyOrdersMob = (id) => {
    setOrders((prev) => {
      let filterData = prev.filter((el) => el.id === id);
      setMobOrderData(filterData[0]);
      setMyOrdersMob(!myOrdersMob);
      return prev;
    });
  };
  useEffect(() => {
    const fetchData1 = async () => {
      getReceipts('userId', user.id);
    };
    fetchData1();
  }, []);
  const handleRefresh = () => {
    setRefresh((prev) => !prev);
  };
  useEffect(() => {
    getWalletAmountFromApi(user?.id);
  }, []);

  setLayoutOptions({ title: t('myMeals'), showKitchen: true });
  const OrderStatusesMap = OrderStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status.id };
  }, {});

  useEffect(() => {
    const fetchData1 = async () => {
      if (!kitchen) return;
      let responce = await useFetchingLoader(
        async () =>
          await http.get(`orders/getAllOrders`, {
            kitchenId: kitchen.id,
            userId: user?.id,
            reservationType: reservationType,
            page: currentPageAllLogs,
            limit: newPageSizeLogs,
            status: reservationStatus,
          })
      );
      setTotalOrders(responce.total);
      setpageCountsLogs(responce.total / newPageSizeLogs);
      const paymentdata = await getPaymentPreferences(kitchen.id, '');
      setPaymentMethod(paymentdata?.selectedPaymentOptions);
      setPaymentKitchenId(kitchen.id);
      console.log('responce', responce.data);

      setOrders(responce.data.filter((order) => order));
    };
    fetchData1();
  }, [
    kitchen,
    refresh,
    currentPageAllLogs,
    reservationType,
    reservationStatus,
  ]);

  useEffect(() => {
    const queryStrings = search.substring(1).split('&');
    if (queryStrings.length) {
      const query = {};
      queryStrings.forEach((el) => {
        const [key, value] = el.split('=');
        query[key] = value;
      });
      let token = query['token'];
      let dealId = query['deal_id'];
      let err = query['err'];
      let errUI = query['errUI'];
      let b = errUI?.split('+')?.join(' ');
      let c = b?.split('%0d%0a')?.join('');
      let errorMsg = c?.split('%2c')?.join('!');
      if (token && dealId) {
        setShowPaymentPopup(!showPaymentPopup);
      }
      if (err) {
        setErrorMessage(errorMsg);
        setShowErrorPopup(!showErrorPopup);
      }
    }
  }, []);

  const handleOrderCancel = (orderId) => {
    setOrders((state) => state.filter((order) => order.id !== orderId));
  };

  const deleteCP = async ({ order, getMealTypeInfo }) => {
    try {
      return await http.delete(`users/deleteCreditPoint`, {
        mealTypeId: order?.mealTypeId,
        point: getMealTypeInfo?.data[0]?.creditPoints,
        kitchenId: order?.kitchenId,
        date: order?.date ?? order?.addDate,
        mealId: order?.mealId,
      });
    } catch (err) {
      return { error: err };
    }
  };

  const revertCollectPoints = async (order) => {
    const getMealTypeInfo = await http.post(`users/getMealTypeInfoById`, {
      mealTypeId: order?.mealTypeId,
      kitchenId: order?.kitchenId,
      date: order?.date ?? order?.addDate,
    });

    if (getMealTypeInfo?.data[0]?.creditPoints) {
      Dialog({
        title:
          i18n.language === 'he'
            ? `${t('revertCollectPointMessage')} ${
                getMealTypeInfo?.data[0]?.creditPoints
              }  ${t('collectPointsMess')}  ${
                MealTypes.find((el) => el?.id == order?.mealTypeId).display
              }?`
            : `${t('revertCollectPointMessage')}  ${
                getMealTypeInfo?.data[0]?.creditPoints
              } ${t('pointMsg')}  ${
                MealTypes.find((el) => el?.id == order?.mealTypeId).display
              }?`,
        type: 'success',
        customFontStyle:
          window.innerWidth > 480 ? 'sw_font_size_24' : 'sw_font_size_20',
        buttons: {
          cancelButtonText: t('back'),
          confirmButtonText: t('ok'),
        },
        onConfirm: async () => {
          const getResponseInfo = await useFetchingLoader(
            async () =>
              await deleteCP({
                order,
                getMealTypeInfo,
              })
          );
          if ('status' in getResponseInfo && getResponseInfo.status === 200) {
            setTimeout(() => {
              SwalDialogs.Dialog({
                title: ` ${t('cancelPointSuccess1')} ${
                  getMealTypeInfo?.data[0]?.creditPoints
                } ${t('cancelPointSuccess2')} ${
                  MealTypes.find((el) => el?.id == order?.mealTypeId).display
                } ${t('cancelPointSuccess3')}`,
                customFontStyle:
                  window.innerWidth > 480
                    ? 'sw_font_size_24'
                    : 'sw_font_size_20',
                type: 'success',
                buttons: {
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonText: t('ok'),
                },
                onClose: async () => {
                  setMyOrdersMob(false);
                  setRefresh((prev) => !prev);
                  getWalletAmountFromApi(user?.id);
                  await getEmployeeLobbyData(order?.kitchenId);
                },
              });
            }, 150);
          }
          if ('error' in getResponseInfo) {
            setTimeout(() => {
              Dialog({
                title: getResponseInfo.error,
                type: 'error',
                buttons: {
                  showCancelButton: false,
                  showConfirmButton: true,
                  confirmButtonText: t('ok'),
                },
                onConfirm: () => {
                  setDummy(true);
                },
              });
            }, 150);
          }
        },
      });
    } else {
      SwalDialogs.Dialog({
        title: `${t('alertNoMealsAbleMessage')}`,
        type: 'error',
        buttons: {
          showCancelButton: true,
          showConfirmButton: false,
        },
      });
    }
  };
  useEffect(() => {
    if (window.innerWidth <= 480) {
      setTableColumn(mobcolumnDefs);
    } else if (window.innerWidth <= 1600 && window.innerWidth > 480) {
      setTableColumn(tabColumnDefs);
    } else {
      setTableColumn(columnDefs);
    }
  }, []);

  const handlePopup = (id) => {
    setTimeout(() => {
      setOrders((prev) => {
        let orderfilterData = prev.filter((el) => el.id === id);
        setViewOrderData(orderfilterData);
        setOrderId(id);
        setPaymentPopup(!paymentPopup);
        return prev;
      });
    }, 175);
  };
  const handlePaymentSuccessPopupClose = () => {
    setShowPaymentPopup(!showPaymentPopup);
    history.push('/myMeals');
  };
  const handlePaymentFailurePopupClose = () => {
    setShowErrorPopup(!showErrorPopup);
    history.push('/myMeals');
  };

  const columnDefs = [
    {
      field: 'reservationKitchen',
      headerName: `${t('reservationKitchen')}`,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${
          params.data?.point > 0
            ? params.data?.kitchen.name
              ? params.data?.kitchen.name
              : '--'
            : params.data?.mealDish
              ? kitchens.find(
                  (k) =>
                    k.id === params.data?.mealDish[0]?.mealDish?.dish?.kitchenId
                )?.name
              : '--'
        }`;
      },
    },

    {
      field: 'mealDate',
      headerName: `${t('mealDate')}`,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${
          params.data?.point > 0
            ? params.data?.meal
              ? dates.ddmmFormat(params.data?.meal?.date)
              : '--'
            : params.data?.mealDish &&
                params.data?.mealDish[0]?.mealDish?.meal?.date
              ? dates.ddmmFormat(params.data?.mealDish[0]?.mealDish?.meal?.date)
              : '--'
        }`;
      },
    },
    {
      field: 'mealType',
      headerName: `${t('mealType')}`,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `
        ${
          MealTypesMap[
            params.data?.mealTypeId
              ? params.data?.mealTypeId
              : params.data?.mealDish[0]?.mealDish?.meal?.mealTypeId
          ]?.display
            ? MealTypesMap[
                params.data?.mealTypeId
                  ? params.data?.mealTypeId
                  : params.data?.mealDish[0]?.mealDish?.meal?.mealTypeId
              ]?.display
            : ''
        }`;
      },
    },
    {
      field: 'isChefMealDish',
      headerName: `${t('isChefMealDish')}`,
      suppressMovable: true,
      cellRenderer: (params) => {
        console.log('params', params);

        return params.data?.orderMealDishes
          ? Boolean(
              params.data?.orderMealDishes.find(
                (el) => el?.mealDish?.isChefMealDish
              )
            )
            ? t('yes')
            : t('no')
          : t('no');
      },
    },
    {
      field: 'name',
      headerName: `${t('dishName')}`,
      suppressMovable: true,
      cellRenderer: (params) => {
        return params?.data?.mealDish &&
          params?.data?.mealDish[0]?.mealDish?.dish?.name
          ? `${params.data.mealDish[0].mealDish.dishes
              .map((el) => el.translations[langId])
              .join(', ')}`
          : params.data?.point > 0
            ? t('pendingCollectedPoint')
            : '--';
      },
    },
    {
      field: 'price',
      headerName: `${t('price')}`,
      suppressMovable: true,

      cellRenderer: (params) => {
        return params.data?.paymentOptionId == 4
          ? '--'
          : `${
              params.data?.point > 0
                ? params.data?.point
                  ? params.data?.point
                  : '--'
                : `${
                    (params.data?.paymentOptionId &&
                      params.data?.paymentOptionId == 5) ||
                    kitchen?.kitchenPayment?.paymentOptionId == 5
                      ? ''
                      : '₪'
                  }${
                    params.data?.user?.roleId == 4 ||
                    params.data?.user?.roleId == 6 ||
                    params.data?.user?.roleId == 8
                      ? params.data?.orderPrice
                      : 0
                  }`
            }`;
      },
    },
    {
      field: 'orderId',
      headerName: `${t('orderId')}`,
      suppressMovable: true,

      cellRenderer: (params) => {
        return `${
          /*Boolean(
            params.data?.chargeResponse && params.data?.chargeResponse !== ''
          )
            ? JSON.parse(params.data?.chargeResponse)?.orderID
              ? JSON.parse(params.data?.chargeResponse)?.orderID
              : JSON.parse(params.data?.chargeResponse)?.deal_id
              ? JSON.parse(params.data?.chargeResponse)?.deal_id
              : '--'
            :*/ params.data?.id
        }`;
      },
    },
    {
      field: 'paymentMethod',
      headerName: t('paymentMethod'),
      width: 200,
      suppressMovable: true,

      cellRenderer: (params) => {
        return `${
          params.data?.paymentOptionId == 6
            ? t('paymentOption-cibus')
            : params.data?.paymentOptionId == 5
              ? t('wallet')
              : params.data?.paymentOptionId == 4
                ? t('paymentOption-salary')
                : params.data?.paymentOptionId == 3
                  ? t('paymentOption-creditCard')
                  : user.roleId === 4
                    ? t('--')
                    : t('invoice')
        }`;
      },
    },
    {
      field: 'status',
      headerName: `${t('status')}`,
      headerClass: 'resizable-header',
      suppressMovable: true,
      cellRenderer: (params) => {
        return params.data?.point
          ? `${
              params.data?.status
                ? params.data?.reported
                  ? t('donePointMsg')
                  : t('approvedPointMsg')
                : t('pending')
            }`
          : `${
              params.data?.paymentStatus
                ? t('paid')
                : params.data?.orderStatusId === 4
                  ? t('pickup')
                  : t('pending')
            }`;
      },
      width: 100,
    },
    {
      field: 'pickupTime',
      headerName: `${t('pickupTime')}`,
      headerClass: 'resizable-header',
      suppressMovable: true,
      cellRenderer: (params) => {
        return params.data?.pickupTime ? params.data?.pickupTime : '--';
      },
      width: 120,
    },
    {
      field: 'action',
      headerName: '',
      cellRenderer: 'OrderActions',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 300,
    },
  ];
  const tabColumnDefs = [
    {
      field: 'reservationKitchen',
      headerName: `${t('reservationKitchen')}`,
      suppressMovable: true,
      minWidth: 100,
      cellRenderer: (params) => {
        return `${
          params.data?.point > 0
            ? params.data?.kitchen.name
              ? params.data?.kitchen.name
              : '--'
            : params.data?.mealDish
              ? kitchens.find(
                  (k) =>
                    k.id === params.data?.mealDish[0]?.mealDish?.dish?.kitchenId
                )?.name
              : '--'
        }`;
      },
    },

    {
      field: 'mealDate',
      headerName: `${t('mealDate')}`,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${
          params.data?.point > 0
            ? params.data?.meal
              ? dates.ddmmFormat(params.data?.meal?.date)
              : '--'
            : params.data?.mealDish &&
                params.data?.mealDish[0]?.mealDish?.meal?.date
              ? dates.ddmmFormat(params.data?.mealDish[0]?.mealDish?.meal?.date)
              : '--'
        }`;
      },
    },
    {
      field: 'mealType',
      headerName: `${t('mealType')}`,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `
        ${
          MealTypesMap[
            params.data?.mealTypeId
              ? params.data?.mealTypeId
              : params.data?.mealDish[0]?.mealDish?.meal?.mealTypeId
          ]?.display
            ? MealTypesMap[
                params.data?.mealTypeId
                  ? params.data?.mealTypeId
                  : params.data?.mealDish[0]?.mealDish?.meal?.mealTypeId
              ]?.display
            : ''
        }`;
      },
    },
    {
      field: 'name',
      headerName: `${t('dishName')}`,
      suppressMovable: true,
      cellRenderer: (params) => {
        return params?.data?.mealDish &&
          params?.data?.mealDish[0]?.mealDish?.dish?.name
          ? `${params.data.mealDish[0].mealDish.dishes
              .map((el) => el.translations[langId])
              .join(', ')}`
          : params.data?.point > 0
            ? t('pendingCollectedPoint')
            : '--';
      },
    },
    {
      field: 'price',
      headerName: `${t('price')}`,
      suppressMovable: true,
      cellRenderer: (params) => {
        return params.data?.paymentOptionId == 4
          ? '--'
          : `${
              params.data?.point > 0
                ? params.data?.point
                  ? params.data?.point
                  : '--'
                : `${
                    (params.data?.paymentOptionId &&
                      params.data?.paymentOptionId == 5) ||
                    kitchen?.kitchenPayment?.paymentOptionId == 5
                      ? ''
                      : '₪'
                  }${
                    params.data?.user?.roleId == 4 ||
                    params.data?.user?.roleId == 6 ||
                    params.data?.user?.roleId == 8
                      ? params.data?.orderPrice
                      : 0
                  }`
            }`;
      },
    },
    {
      field: 'paymentMethod',
      headerName: t('paymentMethod'),
      suppressMovable: true,
      hide: window.innerWidth > 850 ? false : true,
      cellRenderer: (params) => {
        return `${
          params.data?.paymentOptionId == 6
            ? t('paymentOption-cibus')
            : params.data?.paymentOptionId == 5
              ? t('wallet')
              : params.data?.paymentOptionId == 4
                ? t('paymentOption-salary')
                : params.data?.paymentOptionId == 3
                  ? t('paymentOption-creditCard')
                  : user.roleId === 4
                    ? t('--')
                    : t('invoice')
        }`;
      },
    },
    {
      field: 'status',
      headerName: `${t('status')}`,
      headerClass: 'resizable-header',
      hide: window.innerWidth > 850 ? false : true,
      suppressMovable: true,
      minWidth: 90,
      maxWidth: 110,
      cellRenderer: (params) => {
        return params.data?.point
          ? `${
              params.data?.status
                ? params.data?.reported
                  ? t('donePointMsg')
                  : t('approvedPointMsg')
                : t('pending')
            }`
          : `${params.data?.paymentStatus ? t('paid') : t('pending')}`;
      },
    },
    {
      field: 'pickupTime',
      headerName: `${t('pickupTime')}`,
      headerClass: 'resizable-header',
      suppressMovable: true,
      cellRenderer: (params) => {
        return params.data?.pickupTime ? params.data?.pickupTime : '--';
      },
      maxWidth: 130,
    },
    {
      field: 'action',
      headerName: '',
      cellRenderer: 'OrderActions',
      headerClass: 'resizable-header',
      suppressMovable: true,
      minWidth: window.innerWidth > 991.1 ? 275 : 50,
    },
  ];
  const mobcolumnDefs = [
    {
      field: 'name',
      headerName: `${t('dishName')}`,
      suppressMovable: true,
      cellRenderer: (params) => {
        return params?.data?.mealDish && params?.data?.mealDish[0]?.mealDish
          ? `${params.data.mealDish[0].mealDish.dishes
              .map((el) => el.translations[langId])
              .join(', ')}`
          : params.data?.point > 0
            ? t('pendingCollectedPoint')
            : '--';
      },
    },
    {
      field: 'mealDate',
      headerName: `${t('mealDate')} & ${t('type')}`,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${
          params.data?.point > 0
            ? params.data?.meal
              ? dates.ddmmFormat(params.data?.meal?.date)
              : '--'
            : params.data?.mealDish &&
                params.data?.mealDish[0]?.mealDish?.meal?.date
              ? dates.ddmmFormat(params.data?.mealDish[0]?.mealDish?.meal?.date)
              : '--'
        }
       
        ${
          MealTypesMap[
            params.data?.mealTypeId
              ? params.data?.mealTypeId
              : params.data?.mealDish[0]?.mealDish?.meal?.mealTypeId
          ]?.display
        }`;
      },
    },
    {
      field: 'action',
      headerName: '',
      cellRenderer: 'OrderActions',
      headerClass: 'resizable-header',
      suppressMovable: true,
    },
  ];
  const gridOptions = {
    onFilterChanged: () => {},
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };
  return (
    <div className="my-meals my-orders-page">
      <h1 className="page-heading">{t('myOrders')}</h1>
      <div className="my-orders-table-section">
        <div className="my-orders-table">
          <>
            <div className="reservation-management global-data-table reservation-updated-page">
              <div
                className="ag-theme-alpine"
                style={{ height: '100%', width: '100%' }}
              >
                <div className="my-meals-flex">
                  <div className="my-orders-dropdown">
                    <label className="dropdown-label">
                      {t('reservationType')}
                    </label>
                    <select
                      name="reservation_type"
                      onChange={(event) => {
                        setReservationType(event.target.value);
                        setcurrentPageAllLogs(1);
                      }}
                      value={reservationType}
                    >
                      {' '}
                      <option
                        value={'Reservations'}
                        selected={'Reservations' == reservationType}
                      >
                        {t('reservations')}
                      </option>
                      <option
                        value={'Credit points'}
                        selected={'Credit points' == reservationType}
                      >
                        {t('creditPoints')}
                      </option>
                    </select>
                  </div>
                  <div className="my-orders-dropdown">
                    <label className="dropdown-label">{t('mealStatus')}</label>
                    <select
                      name="reservation_type"
                      onChange={(event) => {
                        setReservationStatus(event.target.value);
                        setcurrentPageAllLogs(1);
                      }}
                      value={reservationStatus}
                    >
                      {' '}
                      <option
                        value={'all'}
                        selected={'all' == reservationStatus}
                      >
                        {t('allMealsStatus')}
                      </option>
                      <option
                        value={'previous'}
                        selected={'previous' == reservationStatus}
                      >
                        {t('previousMealsStatus')}
                      </option>
                      <option
                        value={'future'}
                        selected={'future' == reservationStatus}
                      >
                        {t('futureMealsStatus')}
                      </option>
                    </select>
                  </div>
                </div>
                <AgGridReact
                  className="ag-theme-alpine"
                  rowData={
                    orders && orders?.length !== 0
                      ? orders.map((orders) => ({
                          ...orders,
                        }))
                      : []
                  }
                  columnDefs={orders && orders.length !== 0 ? tableColumn : []}
                  localeText={agGridLocales[i18n.language]}
                  domLayout={'autoHeight'}
                  onCellClicked={(e) => {
                    if (!e.colDef.field.includes('action')) {
                      handleMyOrdersMob(e.data.id);
                    }
                  }}
                  gridOptions={gridOptions}
                  onGridReady={(params) => {
                    setGridApi(params.api);
                    setColumnApi(params.columnApi);
                    params.api.sizeColumnsToFit();
                  }}
                  onGridColumnsChanged={(params) => {
                    setGridApi(params.api);
                    setColumnApi(params.columnApi);
                    params.api.sizeColumnsToFit();
                  }}
                  onFirstDataRendered={(params) => {
                    params.api.sizeColumnsToFit();
                  }}
                  frameworkComponents={{
                    OrderActions: (e) => (
                      <OrderActions
                        order={e.data}
                        OrderStatusesMap={OrderStatusesMap}
                        MealTypes={MealTypes}
                        handleOrderCancel={handleOrderCancel}
                        useFetchingLoader={useFetchingLoader}
                        handlePopup={handlePopup}
                        paymentMethod={paymentMethod}
                        paymentKitchenId={paymentKitchenId}
                        setPaymentMethod={setPaymentMethod}
                        setPaymentKitchenId={setPaymentKitchenId}
                        user={user}
                        MealTypesMap={MealTypesMap}
                        revertCollectPoints={revertCollectPoints}
                        handleMyOrdersMob={handleMyOrdersMob}
                        langId={langId}
                        kitchens={kitchens}
                        setRefresh={setRefresh}
                      />
                    ),
                  }}
                  reactUi={true}
                  enableRtl={lang === '"he"' ? true : false}
                  pagination={false}
                  paginationPageSize={50}
                  overlayLoadingTemplate={'<p></p>'}
                  paginationNumberFormatter={function (params) {
                    return params.value.toLocaleString();
                  }}
                ></AgGridReact>
                <div className="pagination-view we-pagination-view">
                  <div className="we_show_records_main">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection:
                          i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                        direction: 'ltr',
                        gap: 5,
                      }}
                    >
                      <p>
                        {newPageSizeLogs * (currentPageAllLogs - 1) + 1} -{' '}
                        {newPageSizeLogs * currentPageAllLogs}
                      </p>
                      <p>{t('of')}</p>
                      <p>{totalOrders}</p>
                      <p>{t('results')}</p>
                    </div>
                  </div>
                  <Pagination
                    style={{
                      display: 'flex',
                      flexDirection:
                        i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                    }}
                  >
                    <Pagination.First
                      disabled={pageCountsLogs <= 1 ? true : false}
                      onClick={() => {
                        setcurrentPageAllLogs(1);
                      }}
                      style={{
                        transform:
                          i18n.dir() === 'rtl'
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)',
                      }}
                    />
                    <Pagination.Prev
                      disabled={pageCountsLogs <= 1 ? true : false}
                      onClick={() => {
                        currentPageAllLogs > 1
                          ? setcurrentPageAllLogs(currentPageAllLogs - 1)
                          : '';
                      }}
                      style={{
                        transform:
                          i18n.dir() === 'rtl'
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)',
                      }}
                    />

                    <Pagination.Item active>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection:
                            i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                          direction: 'ltr',
                          gap: 5,
                        }}
                      >
                        <p style={{ margin: 0 }}>{t('page')}</p>
                        <p style={{ margin: 0 }}>{currentPageAllLogs}</p>
                        <p style={{ margin: 0 }}>{t('of')}</p>
                        <p style={{ margin: 0 }}>
                          {Math.ceil(totalOrders / newPageSizeLogs)}
                        </p>
                      </div>
                    </Pagination.Item>

                    <Pagination.Next
                      disabled={pageCountsLogs <= 1 ? true : false}
                      onClick={() => {
                        currentPageAllLogs <
                        Math.ceil(totalOrders / newPageSizeLogs)
                          ? setcurrentPageAllLogs(currentPageAllLogs + 1)
                          : '';
                      }}
                      style={{
                        transform:
                          i18n.dir() === 'rtl'
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)',
                      }}
                    />
                    <Pagination.Last
                      disabled={pageCountsLogs <= 1 ? true : false}
                      onClick={() => {
                        setcurrentPageAllLogs(
                          Math.ceil(totalOrders / newPageSizeLogs)
                        );
                      }}
                      style={{
                        transform:
                          i18n.dir() === 'rtl'
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)',
                      }}
                    />
                  </Pagination>
                </div>
              </div>
            </div>
          </>
          {viewOrderData && viewOrderData.length > 0 && (
            <Modal
              open={paymentPopup}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              maxWidth="lg"
              className="add-employee-popup copymeal-popup  check-payment-popup meal-payment-popup"
            >
              <Box sx={style}>
                <div className="extras-listing-section">
                  {!(
                    viewOrderData[0]?.reported &&
                    viewOrderData[0]?.paymentStatus
                  ) && (
                    <div className="close-btn">
                      <img src={cancel} alt="close" onClick={handlePopup} />
                    </div>
                  )}
                  <h2>{t('paymentDetails')}</h2>
                  {paymentMethod === 5 && (
                    <>
                      <div className={'extras-meal-management'}>
                        {viewOrderData && viewOrderData[0]?.last4Digits ? (
                          <div className="payment-details">
                            <p>{t('cardLastDigits')}:</p>
                            <h6>
                              {viewOrderData && viewOrderData[0]?.last4Digits}
                            </h6>
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="payment-details">
                          <p>{t('orderId')}:</p>
                          <h6>
                            {viewOrderData && viewOrderData[0]?.id
                              ? viewOrderData[0]?.id
                              : ''}
                          </h6>
                        </div>
                        <div className="payment-details">
                          <p>{t('amountPaid')}:</p>
                          <h6>
                            {viewOrderData &&
                              viewOrderData[0]?.chargeResponse &&
                              JSON.parse(viewOrderData[0]?.chargeResponse)
                                ?.Amount}
                          </h6>
                        </div>
                        <div className="payment-details">
                          <p>{t('timeOfPayment')}:</p>
                          <h6>
                            {viewOrderData &&
                              viewOrderData[0] &&
                              new Date(
                                viewOrderData[0]?.realizeDate
                              ).toLocaleString('he-IL')}
                          </h6>
                        </div>
                        {viewOrderData &&
                        viewOrderData[0]?.chargeResponse &&
                        JSON.parse(viewOrderData[0]?.chargeResponse)?.tranId ? (
                          <div className="payment-details">
                            <p>{t('transactionId')}:</p>
                            <h6>
                              {viewOrderData &&
                                viewOrderData[0]?.chargeResponse &&
                                JSON.parse(viewOrderData[0]?.chargeResponse)
                                  ?.tranId}
                            </h6>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </>
                  )}
                  {paymentMethod === 6 && (
                    <>
                      <div className={'extras-meal-management'}>
                        <div className="payment-details">
                          <p>{t('orderId')}:</p>
                          <h6>
                            {/*viewOrderData &&
                              viewOrderData[0]?.chargeResponse &&
                              JSON.parse(viewOrderData[0]?.chargeResponse)
                  ?.deal_id*/}
                            {/*viewOrderData &&
                              viewOrderData[0]?.chargeResponse &&
                              JSON.parse(viewOrderData[0]?.chargeResponse)
                ?.orderID*/}
                            {viewOrderData && viewOrderData[0]?.id
                              ? viewOrderData[0]?.id
                              : ''}
                          </h6>
                        </div>
                        <div className="payment-details">
                          <p>{t('amountPaid')}:</p>
                          <h6>
                            {user.roleId === 4
                              ? viewOrderData && viewOrderData[0]?.orderPrice
                              : 0}
                          </h6>
                        </div>
                        <div className="payment-details">
                          <p>{t('timeOfPayment')}:</p>
                          <h6>
                            {viewOrderData &&
                              viewOrderData[0] &&
                              new Date(
                                viewOrderData[0]?.updatedAt
                              ).toLocaleString('he-IL')}
                          </h6>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="extras-button other-button">
                    <button onClick={() => setPaymentPopup((prev) => !prev)}>
                      {t('ok')}
                    </button>
                    {/* <button onClick={skipAddOrder}>{t('skip')}</button> */}
                  </div>
                </div>
              </Box>
            </Modal>
          )}
        </div>
        <MyOrdersDetailsMobilePopup
          myOrdersMob={myOrdersMob}
          handleMyOrdersMob={handleMyOrdersMob}
          handleVoucherModal={async () => {
            try {
              await http.get(`orders/${mobOderData?.id}`);
              history.push({
                pathname: `/voucher/${mobOderData?.id}`,
                state: { backText: t('returnToMyMeals') },
              });
            } catch (err) {
              setTimeout(() => Alert({ error: err }), 250);
            }
          }}
          setMyOrdersMob={setMyOrdersMob}
          handleRefresh={handleRefresh}
          mobOderData={mobOderData}
          OrderStatusesMap={OrderStatusesMap}
          MealTypes={MealTypes}
          setOrders={setOrders}
          t={t}
          kitchens={kitchens}
          kitchen={kitchen}
          paymentMethod={paymentMethod}
          handlePopup={handlePopup}
          paymentKitchenId={paymentKitchenId}
          user={user}
          revertCollectPoints={revertCollectPoints}
          receipts={receipts}
        />
        {showPaymentPopup && (
          <CibusPaymentSuccessPopup
            showPaymentPopup={showPaymentPopup}
            t={t}
            handlePaymentSuccessPopupClose={handlePaymentSuccessPopupClose}
          />
        )}
        {showErrorPopup && (
          <CibusPaymentFailurePopup
            showErrorPopup={showErrorPopup}
            t={t}
            handlePaymentFailurePopupClose={handlePaymentFailurePopupClose}
            errorMessage={errorMessage}
          />
        )}
      </div>
    </div>
  );
};

const OrderActions = ({
  order,
  OrderStatusesMap,
  MealTypes,
  handleOrderCancel,
  useFetchingLoader,
  handlePopup,
  dishArray,
  dishIds,
  MealTypesMap,
  cancellation,
  paymentMethod,
  paymentKitchenId,
  setPaymentMethod,
  setPaymentKitchenId,
  user,
  revertCollectPoints,
  handleMyOrdersMob,
  langId,
  kitchens,
  setRefresh,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const isOrder = (order, value) => order?.orderStatusId === value;
  const isFuture = moment(order?.createdAt).isBefore(moment());
  const isFutureMeal = (order) => {
    let { date, mealType } = (order?.mealDish?.meal ||
      (order &&
        order?.mealDish &&
        order?.mealDish.length > 0 &&
        order.mealDish[0].mealDish.meal)) ?? [{}, {}];
    let lastTimeInMeal = MealTypesMap[mealType?.id]?.to;
    let lastTimeToRealizeOrder = moment([date, lastTimeInMeal].join(' '));
    let now = moment();

    return lastTimeToRealizeOrder > now;
  };
  const handleCancelOrder = async () => {
    try {
      await useFetchingLoader(async () => {
        await http.put(`orders/${order.id}/cancel`);
      });
      handleOrderCancel(order.id);
      // setRefresh((prev) => !prev);
      setTimeout(() => {
        SwalDialogs.Dialog({
          title: t('orderWasCanceled'),
          type: 'success',
          buttons: {
            showCancelButton: false,
            confirmButtonText: t('confirm'),
          },
          onConfirm: () => {},
          onClose: () => {
            setRefresh((prev) => !prev);
          },
        });
      }, 150);
    } catch (err) {
      SwalDialogs.Dialog({
        title: 'An error has occurred',
        text: err,
        type: 'error',
        buttons: {
          showCancelButton: false,
          showConfirmButton: false,
        },
      });
    }
  };

  const cancelOrderDialog = () => {
    return SwalDialogs.Dialog({
      title: t('cancelOrderTitle'),
      text: ` ${t('cancelOrderText')} ${order.mealDish[0].mealDish.dishes
        .map((el) => el.translations[langId])
        .join(', ')} ${' \n'} ${t('cancelOrderTextin')} ${
        order?.mealDish[0]?.mealDish?.meal?.date
      } ${t('cancelOrderTextin')} ${
        kitchens.find(
          (k) => k.id === order?.mealDish[0]?.mealDish?.meal?.kitchenId
        )?.name
      } `,
      buttons: {
        confirmButtonText: t('cancelOrderBtnLabel'),
        cancelButtonText: t('back'),
      },
      closeBtnStyle: {},
      onConfirm: handleCancelOrder,
    });
  };

  const cibusPayment = () => {
    SwalDialogs.Dialog({
      title: t('cibusPayment'),
      text: t('cibusPaymentAlert'),
      confirmButtonText: t('redirectToCibusSite'),
      buttons: {
        confirmButtonText: t('redirectToCibusSite'),
        denyButtonText: t('payByOneTimeCode'),
        showCancelButton: false,
        showDenyButton: true,
      },
      showCloseButton: true,
      denyButtonColor: '#0060ca',
      closeBtnStyle: {},
      onDenied: async () => {
        SwalDialogs.DialogWithInputAndErrorMess({
          title: `${t('enterOneTimeCode')}`,
          type: 'number',
          onDidOpen: () => {
            const input = document.getElementById('swal-input1');
            input.addEventListener('input', () => {
              document.getElementById('swal-error').style.display = 'none';
            });
          },
          onPreConfirm: () => {
            const input = document.getElementById('swal-input1').value;
            if (!input || input.length !== 5) {
              document.getElementById('swal-error').style.display = 'block';
              document.getElementById('swal-error').innerText = t(
                'enterValidOneTimeCode'
              );
              return false;
            } else {
              return input;
            }
          },

          onConfirm: async (data) => {
            try {
              const res = await http.post(`users/paymentByOneTimeCode`, {
                oneTimeCode: data.value,
                price: order.orderPrice,
                kitchenId: order.orderMealDishes[0].mealDish.dish.kitchenId,
                orderId: order.id,
              });
              if (res && res.status === 'OK') {
                SwalDialogs.Dialog({
                  title: t('orderWasPaid'),
                  type: 'success',
                  buttons: {
                    showCancelButton: false,
                    showConfirmButton: true,
                  },
                  onConfirm: () => {
                    setRefresh((prev) => !prev);
                  },
                });
              } else if (res && res.status === 'Try again') {
                SwalDialogs.Dialog({
                  title: 'Try again',
                  type: 'warning',
                  buttons: {
                    showCancelButton: false,
                    showConfirmButton: true,
                  },
                });
              } else {
                SwalDialogs.Dialog({
                  title: res,
                  type: 'warning',
                  buttons: {
                    showCancelButton: false,
                    showConfirmButton: true,
                  },
                });
              }
            } catch (e) {
              console.log('e', e);
              SwalDialogs.Dialog({
                title: e,
                type: 'error',
                buttons: {
                  showCancelButton: false,
                  showConfirmButton: false,
                },
              });
            }
          },
          buttons: {
            confirmButtonText: t('submitLabel'),
            cancelButtonText: t('back'),
          },
        });
      },
      onConfirm: handleCibusPayment,
    });
  };

  const handleCibusPayment = async () => {
    let paymentKitchenIdLocal, paymentMethodLocal;
    setPaymentMethod((paymentMethodPrev) => {
      setPaymentKitchenId((paymentKitchenIdPrev) => {
        paymentKitchenIdLocal = paymentKitchenIdPrev;
        paymentMethodLocal = paymentMethodPrev;
        return paymentKitchenIdPrev;
      });
      return paymentMethodPrev;
    });
    const res = await http.post(`users/userPaymentByCibus`, {
      orderId: order.id,
      kitchenId: paymentKitchenId || paymentKitchenIdLocal,
      price: order.orderPrice,
      paymentOptionId: paymentMethod || paymentMethodLocal,
    });
    window.location.href = res.url;
  };

  function isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
  }

  return (
    <>
      <div className="my-orders-table-body">
        <div
          className={`my-orders-table-item ${isFuture ? 'future-card' : ''} ${
            order.orderStatusId === 3 ? 'cancelleSection' : ''
          } remove-back-border`}
        >
          <div className="mobile-popup-btn">
            <TableBtn img={arrow} func={() => handleMyOrdersMob(order?.id)} />
          </div>
          <div className="table-btn-section">
            {isOrder(order, OrderStatusesMap.realized) ? (
              <>
                {isEmpty(order.chargeResponse) === false && (
                  <TableBtn
                    img={voucher}
                    btnName={t('paymentDetails')}
                    func={() => handlePopup(order.id)}
                  />
                )}
                {!order?.rating ? (
                  <div
                    className={'rating'}
                    onClick={() => {
                      history.push({
                        pathname: `/addReview/${order?.id}`,
                        state: { backText: t('returnToMyMeals') },
                      });
                    }}
                  >
                    <TableBtn
                      img={reviewImg}
                      btnName={t('sendReview')}
                      func={''}
                    />
                    {/*(
                    <StaticStars
                      rating={order?.rating || -1}
                      numOfRaters={
                        order?.mealDish[0]?.mealDish?.dish?.numOfRaters
                      }
                      showNumOfRaters={false}
                    />
                    )*/}
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : isFutureMeal(order) ? (
              isOrder(order, OrderStatusesMap.active) ? (
                <>
                  {order.paymentOptionId === 6
                    ? order.paymentOptionId === 6 &&
                      order.paymentStatus === false && (
                        <>
                          <TableBtn
                            img={cibusImg}
                            btnName={t('payment')}
                            func={cibusPayment}
                          />

                          {!(order?.reported && order?.paymentStatus) && (
                            <TableBtn
                              img={edit}
                              btnName={i18n.language !== 'ru' ? t('edit') : ''}
                              func={() =>
                                history.push({
                                  pathname: `/orderSummary`,
                                  state: {
                                    date: order?.mealDish[0]?.mealDish?.meal
                                      ?.date,
                                    mealType:
                                      order?.mealDish[0]?.mealDish?.meal
                                        ?.mealTypeId,
                                    slotId: order?.mealSlotId,
                                    orderId: order.id,
                                    dishId:
                                      order?.mealDish[0]?.mealDish?.dish?.id,
                                    dishIds:
                                      order?.mealDish[0]?.mealDish?.dishes,
                                    backLink: '/myMeals',
                                    backText: t('returnToMyMeals'),
                                  },
                                })
                              }
                              tooltip={i18n.language === 'ru' ? t('edit') : ''}
                            />
                          )}
                        </>
                      )
                    : !(order?.reported && order?.paymentStatus) && (
                        <TableBtn
                          img={edit}
                          btnName={i18n.language !== 'ru' ? t('edit') : ''}
                          func={() =>
                            history.push({
                              pathname: `/orderSummary`,
                              state: {
                                date: order?.mealDish[0]?.mealDish?.meal?.date,
                                mealType:
                                  order?.mealDish[0]?.mealDish?.meal
                                    ?.mealTypeId,
                                slotId: order?.mealSlotId,
                                orderId: order.id,
                                dishId: order?.mealDish[0]?.mealDish?.dish?.id,
                                dishIds: order?.mealDish[0]?.mealDish?.dishes,
                                backLink: '/myMeals',
                                backText: t('returnToMyMeals'),
                              },
                            })
                          }
                          tooltip={i18n.language === 'ru' ? t('edit') : ''}
                        />
                      )}
                  {cancellation === true &&
                  !(order.reported && order.paymentStatus) ? (
                    <TableBtn
                      img={cancelImg}
                      btnName={i18n.language !== 'ru' ? t('cancel') : ''}
                      disabled
                      func={cancelOrderDialog}
                      tooltip={i18n.language === 'ru' ? t('cancel') : ''}
                    />
                  ) : (
                    !(order.reported && order.paymentStatus) && (
                      <TableBtn
                        img={cancelImg}
                        btnName={i18n.language !== 'ru' ? t('cancel') : ''}
                        func={cancelOrderDialog}
                        tooltip={i18n.language === 'ru' ? t('cancel') : ''}
                      />
                    )
                  )}
                  <TableBtn
                    img={voucher}
                    btnName={i18n.language !== 'ru' ? t('voucher') : ''}
                    func={async () => {
                      try {
                        await http.get(`orders/${order?.id}`);
                        history.push({
                          pathname: `/voucher/${order?.id}`,
                          state: { backText: t('returnToMyMeals') },
                        });
                      } catch (err) {
                        setTimeout(() => Alert({ error: err }), 250);
                      }
                    }}
                    tooltip={i18n.language === 'ru' ? t('voucher') : ''}
                  />
                </>
              ) : (
                <p className={'position left'}>
                  {isOrder(order, OrderStatusesMap.canceled)
                    ? t('orderWasCanceled')
                    : t('orderNotActive')}
                </p>
              )
            ) : /* Past Orders */
            order.point > 0 ? (
              <>
                {order?.reported ? (
                  order?.status ? (
                    <ActiveButton
                      additionalClassName="lobby-button"
                      label={t('donePointMsg')}
                    />
                  ) : (
                    <ActiveButton
                      additionalClassName="lobby-button"
                      label={t('pending')}
                      cb={() => {
                        revertCollectPoints(order);
                      }}
                    />
                  )
                ) : (
                  <ActiveButton
                    additionalClassName="lobby-button"
                    label={
                      order?.status == 1 ? t('approvedPointMsg') : t('pending')
                    }
                    cb={() => {
                      revertCollectPoints(order);
                    }}
                  />
                )}
              </>
            ) : (
              <p className={'single-content'}>
                {isOrder(order, OrderStatusesMap.canceled)
                  ? t('orderWasCanceled')
                  : t('orderNotRealized')}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  kitchen: state.kitchens.current,
  kitchens: state.kitchens.options,
  MealTypes: state.app.enums.MealType,
  OrderStatuses: state.app.enums.OrderStatus,
  receipts: state.app.receipts,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  getPaymentPreferences,
  getEmployeeLobbyData,
  getWalletAmountFromApi,
  getReceipts,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyMeals);
