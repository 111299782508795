import React, { useEffect, useRef, useState, useMemo } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  showAlert,
} from '../../store/app/actions';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import WEIcon from '../../components/WE-IconButton';
import { useTranslation } from 'react-i18next';
import http from '../../services/http';
import Select from 'react-select';
import SearchLineWithButton from '../../components/SearchLineWithButton';
import { isTabletOrMobile } from '../../services/mediaQueries';
import { sleep } from '../../services/utils';
import { Alert, Dialog } from '../../components/Dialogs/SwalDialogs';
import { ActiveButton } from '../../components/WE-Button';
import { validateEmployeenew } from '../../services/validators';
import { Autocomplete } from '@material-ui/lab';
import Tippy from '@tippyjs/react';
import { StaticStars } from '../../components/RatingStars';
import { sortMeals } from '../../services/sortItems';
import dates from '../../services/dates';
import WEBackButton from '../../components/WE-BackButton';
import BackLink from '../../components/BackLink';
import docImg from '../../assets/icons/icon-docs.svg';
import next from '../../assets/images/next.png';
import ViewCopyPreviousMealDetail from './viewCopyPreviousMealDetail';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import close from '../../assets/icons/closeBlack.svg';
import searchimg from '../../assets/images/Search.svg';
import moment from 'moment';
import Pagination from 'react-bootstrap/Pagination';

const initialState = {
  userId: null,
  fName: '',
  lName: '',
  phone: '',
  email: '',
  password: '',
  role: [],
  kitchensIds: [],
  employeeId: '',
  card: '',
  status: 'active',
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '25px 35px',
  p: 6,
  borderRadius: '5px',
  maxWidth: '550px',
  width: '100%',
  minHeight: '300px',
};

const CopyPreviousMeal = ({
  user,
  kitchen,
  setLayoutOptions,
  Roles,
  UserStatuses,
  Kitchens,
  useLoader,
  useFetchingLoader,
  MealStatuses,
  MealTypes,
  currentLanguage,
  Languages,
}) => {
  const { t, i18n } = useTranslation();
  const { mealTypeId, date } = useParams();
  const history = useHistory();
  const [usersToDisplay, setUsersToDisplay] = useState(null);
  const [kitchensIds, setKitchensIds] = useState([]);
  const [payload, setPayload] = useState(initialState);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalItems, setTotalItems] = useState(0);
  const [pageSize] = useState(50); // Records per page
  const [pageCounts, setpageCounts] = useState(1); // Total pages count

  const [open, setOpen] = useState(false);
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [update, setUpdate] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [prefetchData, setPrefetchData] = useState(null);
  const [rolesIds, setRolesIds] = useState();
  const [dummy, setDummy] = useState();
  const [copyData, setCopyData] = useState();
  const [resendData, setResendData] = useState();
  const [copied, setCopied] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [errors, setErrors] = useState({});
  const [previousMeal, setPreviousMeal] = useState(null);
  const { backText, backLink } = location.state ?? {};
  const [todate, setDate] = useState(date);
  const [alreadyAdded, setAlreadyAdded] = useState();
  const [copyMeal, setCopyMeal] = useState(false);
  const [tableColumn, setTableColumn] = useState();
  const [viewDetails, setViewDetails] = useState(false);
  const [details, setDetails] = useState();
  const [copyPopup, setCopyPopup] = useState(false);
  const [countDishes, setCountDishes] = useState();
  const [mealData, setMealData] = useState();
  const [dishPopup, setDishPopup] = useState(false);
  const [dishData, setDishData] = useState();
  const [newMealType, setNewMealType] = useState();
  const [newMealTypeId, setNewMealTypeId] = useState(null);
  const [updatedData, setUpdatedData] = useState();
  const [copyMealTypeName, setCopyMealTypeName] = useState();
  const [disabled, setDisabled] = useState(false);
  // const [data, setData] = useState(
  //   MyCheckBoxList.sort((a, b) => a.order - b.order)
  // );
  const UserStatusesMap = UserStatuses.reduce((acc, status) => {
    return { ...acc, [status.value]: status.id };
  }, {});
  const RolesMap = Roles.reduce((acc, role) => {
    return { ...acc, [role.value]: role.id };
  }, {});
  const [mealTypeName, setMealTypeName] = useState([null]);
  /***********fetch User data */

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    let formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  useEffect(() => {
    if (mealTypeId) {
      const mealty = MealTypes.filter(
        (el) => el.id === Number.parseInt(mealTypeId)
      );

      if (mealty.length > 0) {
        setMealTypeName(mealty[0].display);
      }
    }
  }, [mealTypeId]);

  const MealStatusMap = MealStatuses.reduce((acc, meal) => {
    return { ...acc, [meal.value]: meal.id };
  }, {});
  const MealTypesMap = MealTypes.reduce((acc, meal) => {
    return { ...acc, [meal.id]: meal.display };
  }, {});

  const mealTypeFilter = MealTypes.filter((el) => el.kitchenId === kitchen.id);
  useEffect(() => {
    const fetchData1 = async () => {
      setLayoutOptions({
        title: `${t('addDishToMeal')}`,
        showDatePicker: true,
        isDatePickerClickable: false,
      });

      if (!kitchen) return;
      const mealTypeIdForFilter = newMealTypeId ?? mealTypeId;
      const mealTypeIdLocal =
        Array.isArray(mealTypeFilter) && mealTypeFilter.length !== 0
          ? mealTypeFilter.find((el) => el.id == mealTypeIdForFilter)
            ? mealTypeFilter.find((el) => el.id == mealTypeIdForFilter).id
            : mealTypeFilter[0].id
          : mealTypeIdForFilter;
      const { data, total } = await useFetchingLoader(
        async () =>
          await http.get(`meals/getAllMeal`, {
            filter: {
              kitchenId: kitchen.id,
              mealStatusId: [MealStatusMap.active, MealStatusMap.blocked],
              mealTypeId: mealTypeIdLocal,
            },
            page: currentPage,
            limit: pageSize,
            order: [['date', 'DESC']],
            meta: true,
          })
      );
      setTotalItems(total);
      setpageCounts(Math.ceil(total / pageSize));
      setPreviousMeal(
        sortMeals(
          data.filter((ele) => ele.mealType.id === Number(mealTypeIdLocal))
        )
      );
      setUpdatedData(data);
      // setMealsToDisplay(sortMeals(data));
      let aa = sortMeals(
        data.filter((ele) => ele.mealType.id === Number(mealTypeIdLocal))
      );
      let copyDataMap = aa.map((u, i) => ({
        id: u.id,
        date: u?.date,
        day: u?.day,
        mealtype: MealTypesMap[u?.mealTypeId],
        inventory: u?.inventory,
        NoofDishes: u?.mealDish,
        Chefdish: u?.mealDish,
        order: u?.ordered,
        realized: u?.realized,
        kitchenId: u?.kitchenId,
        ratings: u?.rating,
        numOfRaters: u?.numOfRaters,
        mealTypeId: u?.mealTypeId,
      }));

      setUsersToDisplay(copyDataMap);
    };
    fetchData1();
  }, [mealTypeId, currentPage, kitchen]);

  /************global filter funcitionm */
  const onFilterTextBoxChanged = (e) => {
    const value = e.target.value;
    gridApi.setQuickFilter(value);
  };

  /***********Export CSV file */
  const onBtnExport = () => {
    gridApi.exportDataAsCsv();
  };

  const handleSearchChange = (e) => {
    let value = e.target.value;
    if (value.includes('/') === true) {
      value = moment(value).format('YYYY-DD-MM');
    }
    gridApi.setQuickFilter(value);
  };
  const onPageSizeChanged = (newPageSize) => {
    var value = document.getElementById('page-size').value;
    gridApi.paginationSetPageSize(Number(value));
  };

  // ag grid data
  const columnDefs = [
    {
      field: 'date',
      headerName: `${t('dateDay')}`,
      suppressMovable: true,
      headerClass: 'resizable-header',
      cellRenderer: (params) => {
        return `${formatDate(new Date(params?.data?.date))} <br /> <b>${
          params?.data?.day
        }</b>`;
      },
    },
    {
      field: 'day',
      headerName: `${t('dateDay')}`,

      hide: true,
      suppressMovable: true,
      headerClass: 'resizable-header',

      cellRenderer: (params) => {
        return `${formatDate(new Date(params?.data?.date))} <br /> <b>${
          params?.data?.day
        }</b>`;
      },
    },
    {
      field: 'mealtype',
      headerName: `${t('mealtype')}`,
      // cellRenderer: (params) => {
      //   return `${params?.data?.mealtype}`;
      // },
      cellRenderer: 'MealTypeFilter',

      suppressMovable: true,
    },
    {
      field: 'order',
      headerName: t('orders'),
      cellRenderer: 'PercentData',
      headerClass: 'resizable-header',
      suppressMovable: true,

      width: '150px',
    },
    {
      field: 'realized',
      headerName: t('realized'),
      cellRenderer: 'PercentDataRealized',
      headerClass: 'resizable-header',
      suppressMovable: true,

      width: '150px',
    },
    {
      field: 'inventory',
      headerName: t('inventory'),

      suppressMovable: true,
    },
    {
      field: 'Chefdish',
      headerName: `${t('chefDish')}`,
      cellRenderer: 'Chefdish',

      suppressMovable: true,
    },
    {
      field: 'NoofDishes',
      headerName: t('noofDishes'),
      cellRenderer: 'NoofDishes',
      suppressMovable: true,
    },
    // {
    //   field: 'Rating',
    //   headerName: 'Ratings',
    //   cellRenderer: 'RatingStars',
    //   headerClass: 'resizable-header',
    //   suppressMovable: true,
    // },
    {
      //   field: 'Action',
      headerName: t('action'),
      cellRenderer: 'ActionButton',
      headerClass: 'resizable-header',
      suppressMovable: true,
    },
  ];

  /**** tab view column */
  const columnDefsTab = [
    {
      field: 'date',
      headerName: `${t('dateDay')}`,
      suppressMovable: true,
      headerClass: 'resizable-header',

      cellRenderer: (params) => {
        return `${formatDate(new Date(params?.data?.date))} <br /> <b>${
          params?.data?.day
        }</b>`;
      },
    },
    {
      field: 'day',
      headerName: `${t('dateDay')}`,

      hide: true,
      suppressMovable: true,
      headerClass: 'resizable-header',

      cellRenderer: (params) => {
        return `${formatDate(new Date(params?.data?.date))} <br /> <b>${
          params?.data?.day
        }</b>`;
      },
    },

    {
      field: 'mealtype',
      headerName: `${t('mealtype')}`,
      cellRenderer: (params) => {
        return `${params?.data?.mealtype}`;
      },

      suppressMovable: true,
    },
    {
      field: 'order',
      headerName: t('orders'),
      cellRenderer: 'PercentData',
      headerClass: 'resizable-header',
      suppressMovable: true,

      width: '150px',
    },

    {
      field: 'NoofDishes',
      headerName: t('noofDishes'),
      cellRenderer: 'NoofDishes',
      suppressMovable: true,
    },

    {
      headerName: t('action'),
      cellRenderer: 'ActionButton',
      headerClass: 'resizable-header',
      suppressMovable: true,
      unSortIcon: true,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];

  /**** mobile view column */
  const columnDefsMob = [
    {
      field: 'date',
      headerName: `${t('dateDay')}`,

      suppressMovable: true,
      headerClass: 'resizable-header',
      cellRenderer: (params) => {
        return `${formatDate(new Date(params?.data?.date))} <br /> <b>${
          params?.data?.day
        }</b>`;
      },
    },
    {
      field: 'mealtype',
      headerName: `${t('mealtype')}`,
      cellRenderer: (params) => {
        return `${params?.data?.mealtype}`;
      },
      suppressMovable: true,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 100,
    },
  ];

  const defaultColDef = {
    resizable: true,
    suppressMovable: true,
  };

  const gridOptions = {
    rowHeight: 80,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };

  const lang = localStorage.getItem('language');
  const [newRoles, setNewRoles] = useState();
  useEffect(() => {
    if (user.roleId === 1) {
      setNewRoles(Roles);
    } else if (user.roleId !== 1) {
      let aa = Roles.filter((el) => el.id !== 1);
      setNewRoles(aa);
    }
  }, [user]);

  const handleOption = async (e) => {
    let mealTypeId1 = e.target.value;
    setNewMealTypeId(mealTypeId1);
    const { data, total } = await useFetchingLoader(
      async () =>
        await http.get(`meals/getAllMeal`, {
          filter: {
            kitchenId: kitchen.id,
            mealStatusId: [MealStatusMap.active, MealStatusMap.blocked],
            mealTypeId: mealTypeId1,
          },
          page: 1,
          limit: pageSize,
          order: [['date', 'DESC']],
          meta: true,
        })
    );
    setCurrentPage(1);
    setTotalItems(total);
    setpageCounts(Math.ceil(total / pageSize));
    setPreviousMeal(
      sortMeals(data.filter((ele) => ele.mealType.id === Number(mealTypeId1)))
    );
    setUpdatedData(data);
    // setMealsToDisplay(sortMeals(data));
    let aa = sortMeals(
      data.filter((ele) => ele.mealType.id === Number(mealTypeId1))
    );
    let copyDataMap = aa.map((u, i) => ({
      id: u.id,
      date: u?.date,
      day: u?.day,
      mealtype: MealTypesMap[u?.mealTypeId],
      inventory: u.inventory,
      NoofDishes: u.mealDish,
      Chefdish: u?.mealDish,
      order: u.ordered,
      realized: u.realized,
      kitchenId: u.kitchenId,
      ratings: u.rating,
      numOfRaters: u.numOfRaters,
      mealTypeId: u?.mealTypeId,
    }));
    if (mealTypeId1) {
      const mealty = MealTypes.filter(
        (el) => el.id === Number.parseInt(mealTypeId1)
      );

      if (mealty.length > 0) {
        setCopyMealTypeName(mealty[0].display);
      }
    }
    setUsersToDisplay(copyDataMap);
  };

  const handleAddDishAll = async (mealData, selectedId) => {
    if (mealData) {
      const d = { ...mealData.Chefdish };
      const propertyValues = Object.values(d);
      const countDish = propertyValues.length;

      setCopyPopup(true);
      setCountDishes(countDish);
      setMealData(mealData);
      // Dialog({
      //   title: `Copy ${countDishes} dishes from ${dates.formatServer(
      //     mealData.date
      //   )}  ${mealTypeName} to ${todate} ${mealTypeName}? `,
      //   buttons: {
      //     cancelButtonText: t('cancel'),
      //     confirmButtonText: t('ok'),
      //   },
      //   onConfirm: async () => {
      //     let date = dates.formatServer(mealData.date);
      //     let dnew = dates.formatServer(todate);
      //     let requestValues = {
      //       // dishId: di,
      //       mealId: mealData.id,
      //       date,
      //       kitchenId: mealData.kitchenId,
      //       mealTypeId: Number(mealTypeId),
      //       cdate: dnew,
      //     };
      //     try {
      //       await useFetchingLoader(
      //         async () =>
      //           await http.post(`mealsDishes/addPreviousDishes`, requestValues)
      //       );
      //       setDummy(true);
      //       history.goBack();
      //       setAlreadyAdded(true);
      //       setCopyMeal(!copyMeal);
      //       // Update dishes, set the added dish as included
      //     } catch (err) {
      //       Alert({ error: err });
      //     }
      //   },
      // });
    }
  };
  const onConfirm = async (mealData) => {
    setDisabled(true);
    let date = dates.formatServer(mealData?.date);
    let dnew = dates.formatServer(todate);
    console.log('mealData', mealData);

    let requestValues = {
      mealId: mealData?.id,
      date,
      kitchenId: mealData?.kitchenId,
      mealTypeId: Number(mealTypeId),
      cdate: dnew,
    };

    try {
      await useFetchingLoader(
        async () =>
          await http.post(`mealsDishes/addPreviousDishes`, requestValues)
      );
      setDummy(true);
      history.goBack();
      setAlreadyAdded(true);
      setCopyMeal(!copyMeal);
      // Update dishes, set the added dish as included
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    } finally {
      setTimeout(() => {
        setDisabled(false);
      }, 1000);
    }
  };
  const selectDish = async (mealData) => {
    setDishPopup(true);
    const d = { ...mealData?.NoofDishes };
    const propertyValues = Object.values(d);
    setDishData(propertyValues);
  };

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 479) {
      setTableColumn(columnDefsTab);
    } else if (window.innerWidth <= 480) {
      setTableColumn(columnDefsMob);
    } else {
      setTableColumn(columnDefs);
    }
  }, []);

  const handleViewMore = (data) => {
    setViewDetails(!viewDetails);
    setDetails(data);
  };

  const isVerified = useMemo(() => {
    return dishData?.every((d) => d.checked);
  }, [dishData]);

  const onDishConfirm = async (dishData) => {
    let date = dates.formatServer(mealData?.date);
    let dnew = dates.formatServer(todate);
    let requestValues = {
      mealId: mealData?.id,
      date,
      kitchenId: mealData?.kitchenId,
      mealTypeId: Number(mealTypeId),
      //copyMealTypeId:newMealTypeId?Number(newMealTypeId):Number(mealTypeId),
      cdate: dnew,
    };
    const filter = dishData.filter((el) => el.checked === true);
    requestValues = { ...requestValues, dishData: filter };
    try {
      await useFetchingLoader(
        async () =>
          await http.post(`mealsDishes/addPreviousDishes`, requestValues)
      );
      setDummy(true);
      history.goBack();
      setAlreadyAdded(true);
      setCopyMeal(!copyMeal);
      // Update dishes, set the added dish as included
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  return (
    <div className="global-data-table copy-previous-meal user-management">
      <BackLink text={backText} redirectHistory={backLink} />
      <div className="reservation-management global-data-table reservation-updated-page">
        <div className="container">
          <div className="add-employee-btn">
            <div className="copy-previous-meal-header">
              <div className="filter-section">
                <div className="xyz">
                  <select onChange={(event) => handleOption(event)}>
                    {mealTypeFilter?.map((el) => (
                      <option value={el.id} selected={el.id == mealTypeId}>
                        {el.display}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="search-input">
                <img src={searchimg} alt="search" />
                <input
                  type="text"
                  id="filter-text-box"
                  placeholder={t('freeTextSearch')}
                  onInput={handleSearchChange}
                />
              </div>
            </div>
            <div className="active-button">
              <div className="total-data">
                <p>
                  {t('totalData')}: <span>{totalItems}</span>
                </p>
              </div>
              {/*<div className="actions">
                <img src={docImg} alt="icon" onClick={() => onBtnExport()} />
                    </div>*/}
            </div>
          </div>
          <div
            className="ag-theme-alpine"
            style={{ height: '100%', width: '100%' }}
          >
            <AgGridReact
              className="ag-theme-alpine"
              rowData={usersToDisplay}
              columnDefs={tableColumn}
              reactUi={lang === '"he"' ? false : true}
              enableRtl={lang === '"he"' ? true : false}
              domLayout={'autoHeight'}
              gridOptions={gridOptions}
              rowSelection="multiple"
              onGridReady={(params) => {
                setGridApi(params.api);
                setColumnApi(params.columnApi);
                params.api.sizeColumnsToFit();
                document
                  .querySelector('.ag-pinned-left-cols-container')
                  .remove();
              }}
              onFirstDataRendered={(params) => {
                params.api.sizeColumnsToFit();
              }}
              frameworkComponents={{
                RatingStars: (e) => (
                  <RatingStars
                    e={e}
                    previousMeal={previousMeal}
                    t={t}
                    usersToDisplay={usersToDisplay}
                  />
                ),
                PercentData: (e) => (
                  <PercentData
                    e={e}
                    previousMeal={previousMeal}
                    t={t}
                    usersToDisplay={usersToDisplay}
                  />
                ),
                // MealTypeFilter: (e) => (
                //   <MealTypeFilter
                //     e={e}
                //     mealTypeFilter={mealTypeFilter}
                //     t={t}
                //     handleOption={handleOption}
                //     mealTypeId={mealTypeId}
                //   />
                // ),
                PercentDataRealized: (e) => (
                  <PercentDataRealized
                    e={e}
                    previousMeal={previousMeal}
                    t={t}
                    usersToDisplay={usersToDisplay}
                  />
                ),
                NoofDishes: (e) => (
                  <NoofDishes
                    e={e}
                    previousMeal={previousMeal}
                    t={t}
                    usersToDisplay={usersToDisplay}
                    gridApi={gridApi}
                    currentLanguage={currentLanguage}
                  />
                ),
                Chefdish: (e) => (
                  <Chefdish
                    e={e}
                    previousMeal={previousMeal}
                    t={t}
                    usersToDisplay={usersToDisplay}
                    Languages={Languages}
                  />
                ),
                ActionButton: (e) => (
                  <ActionButton
                    e={e}
                    previousMeal={previousMeal}
                    t={t}
                    usersToDisplay={usersToDisplay}
                    handleAddDishAll={handleAddDishAll}
                  />
                ),

                ViewMore: (e) => (
                  <ViewMore e={e} handleViewMore={handleViewMore} />
                ),
              }}
              debug={false}
              overlayLoadingTemplate={'<p></p>'}
              rowGroupPanelShow={'always'}
              pivotPanelShow={'always'}
              enableRangeSelection={true}
              pagination={false}
              paginationPageSize={10}
              paginationNumberFormatter={function (params) {
                return '[' + params.value.toLocaleString() + ']';
              }}
            ></AgGridReact>
            <div className="pagination-view we-pagination-view">
              <div className="we_show_records_main">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                    direction: 'ltr',
                    gap: 5,
                  }}
                >
                  <p>
                    {pageSize * (currentPage - 1) + 1} -{' '}
                    {pageSize * currentPage}
                  </p>
                  <p>{t('of')}</p>
                  <p>{totalItems}</p>
                  <p>{t('results')}</p>
                </div>
              </div>
              <Pagination
                style={{
                  display: 'flex',
                  flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                }}
              >
                <Pagination.First
                  disabled={currentPage <= 1 ? true : false}
                  onClick={() => {
                    setCurrentPage(1);
                  }}
                  style={{
                    transform:
                      i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
                <Pagination.Prev
                  disabled={currentPage <= 1 ? true : false}
                  onClick={() => {
                    currentPage > 1 ? setCurrentPage(currentPage - 1) : '';
                  }}
                  style={{
                    transform:
                      i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />

                <Pagination.Item active>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection:
                        i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                      direction: 'ltr',
                      gap: 5,
                    }}
                  >
                    <p style={{ margin: 0 }}>{t('page')}</p>
                    <p style={{ margin: 0 }}>{currentPage}</p>
                    <p style={{ margin: 0 }}>{t('of')}</p>
                    <p style={{ margin: 0 }}>
                      {Math.ceil(totalItems / pageSize)}
                    </p>
                  </div>
                </Pagination.Item>

                <Pagination.Next
                  disabled={pageCounts <= 1 ? true : false}
                  onClick={() => {
                    currentPage < Math.ceil(totalItems / pageSize)
                      ? setCurrentPage(currentPage + 1)
                      : '';
                  }}
                  style={{
                    transform:
                      i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
                <Pagination.Last
                  disabled={pageCounts <= 1 ? true : false}
                  onClick={() => {
                    setCurrentPage(Math.ceil(totalItems / pageSize));
                  }}
                  style={{
                    transform:
                      i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                />
              </Pagination>
            </div>
          </div>
        </div>
      </div>
      <ViewCopyPreviousMealDetail
        open={viewDetails}
        details={details}
        handleClose={handleViewMore}
        handleAddDishAll={handleAddDishAll}
        currentLanguage={currentLanguage}
        previousMeal={previousMeal}
      />
      {/* <div className="pagination-header">
        {t('pageSize')}:
        <select onChange={() => onPageSizeChanged()} id="page-size">
          <option value="10" selected={true}>
            10
          </option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div> */}
      <Modal
        open={copyPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="lg"
        className="add-employee-popup copymeal-popup  check-payment-popup"
      >
        <Box sx={style}>
          <div className="copyMealpopup-inner">
            <Button className="closeBtn" onClick={() => setCopyPopup(false)}>
              <img src={close} alt="icon" />
            </Button>
            <>
              <p className="copyMealTxt">{`${`Copy ${countDishes} dishes from ${dates.formatServer(
                mealData?.date
              )}  ${
                copyMealTypeName ? copyMealTypeName : mealTypeName
              } to ${todate} ${mealTypeName}? `}`}</p>
              <div className="copyMeal-modal-button-section">
                <Button
                  onClick={() => selectDish(mealData)}
                  className="selectbtn"
                >
                  <i class="las la-mouse-pointer"></i> {t('selectDishes')}
                </Button>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '30%',
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={() => onConfirm(mealData)}
                    className="approvebtn"
                    disabled={disabled}
                  >
                    <i class="las la-check-circle"></i> {t('approve')}
                  </Button>
                  <Button
                    className="cancelbtn"
                    onClick={() => setCopyPopup(false)}
                  >
                    <i class="las la-times-circle"></i> {t('cancel')}
                  </Button>
                </div>
              </div>
            </>
          </div>
        </Box>
      </Modal>
      <Modal
        open={dishPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="lg"
        className="add-employee-popup copymeal-popup  check-payment-popup"
      >
        <Box sx={style}>
          <div className="selectDish-inner-popup">
            <h4 className="selectDish-popup-title">{t('selectDishes')}</h4>
            {dishData && (
              <>
                <div className="hover-popup">
                  {/* {dishData &&
            dishData.map((el,index) => (
              <><input
              type="checkbox"
              id={`checkbox-${index}`}
              checked={isChecked}
              onChange={() => checkHandler}
            />{" "}
            <label htmlFor={`checkbox-${index}`}>{el.name[currentLanguage.id]}</label><br/>
            </>
            ))} */}
                  {dishData?.map((obj, index) => (
                    <div className="select-dish-name">
                      <Checkbox
                        obj={obj}
                        onChange={(item) => {
                          setDishData(
                            dishData.map((d) =>
                              d.dishId === item.dishId ? item : d
                            )
                          );
                        }}
                        currentLanguage={currentLanguage}
                      />
                      <br />
                    </div>
                  ))}
                </div>
              </>
            )}

            <div className="copyMeal-modal-button-section">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '30%',
                  paddingTop: 20,
                  width: '100%',
                }}
              >
                <Button
                  onClick={() => onDishConfirm(dishData)}
                  className="approvebtn"
                >
                  <i class="las la-check-circle"></i> {t('confirm')}
                </Button>
                <Button
                  className="cancelbtn"
                  onClick={() => setDishPopup(false)}
                >
                  <i class="las la-times-circle"></i> {t('cancel')}
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

const Checkbox = ({ obj, onChange, currentLanguage }) => {
  return (
    <>
      <input
        type="checkbox"
        id={`custom-checkbox-${obj.index}`}
        name={obj.name[currentLanguage.id]}
        value={obj.checked}
        onChange={() => onChange({ ...obj, checked: !obj.checked })}
      />{' '}
      {obj.name[currentLanguage.id]}
    </>
  );
};

const RatingStars = ({ e, previousMeal, t, usersToDisplay }) => {
  return (
    <section className={'section2'}>
      <div className={'rating'}>
        <StaticStars
          numOfRaters={e?.data?.numOfRaters || 0}
          rating={e?.data?.ratings || -1}
        />
      </div>
    </section>
  );
};

const PercentData = ({ e, previousMeal, t, usersToDisplay }) => {
  const percentToDisplay = (value, outOfValue) =>
    outOfValue > 0 ? Number.parseInt((value / outOfValue) * 100) : 0;
  return (
    <>
      <div className={'percent-data'}>
        <p>
          {previousMeal?.ordered}{' '}
          {`(${percentToDisplay(e.data?.order, e.data?.inventory)}%)`}
        </p>
      </div>
    </>
  );
};

// const MealTypeFilter = ({ e, mealTypeFilter, t,handleOption,mealTypeId}) => {
//   return (
//     <>
//       <div className={'percent-data'}>
//        <select onChange={(event)=>handleOption(event,e.data?.date,e.data?.id)}>
//         {mealTypeFilter?.map((el)=>(
//           <option value={el.id} selected={el.id == mealTypeId}>{el.value}</option>
//         ))}
//        </select>
//       </div>
//     </>
//   );
// };
const PercentDataRealized = ({ e, previousMeal, t, usersToDisplay }) => {
  const percentToDisplay = (value, outOfValue) =>
    outOfValue > 0 ? Number.parseInt((value / outOfValue) * 100) : 0;
  return (
    <>
      <div className={'percent-data'}>
        <p>
          {previousMeal?.ordered}{' '}
          {`(${percentToDisplay(e.data?.realized, e.data?.inventory)}%)`}
        </p>
        {/* <p className={'percent'}></p> */}
      </div>
    </>
  );
};

const Chefdish = ({ e, previousMeal, t, usersToDisplay, Languages }) => {
  const d = { ...e.data?.Chefdish };
  const propertyValues = Object.values(d);
  let filter = propertyValues.filter((ele) => ele.isChefMealDish === true);
  return (
    <>
      <div className={'percent-data'}>
        <p className={'inventory'}>
          {`${
            filter.length > 0
              ? filter.map(
                  (el) =>
                    el.name[
                      Number(
                        localStorage.getItem('languageId') ??
                          Languages.find(
                            (el) =>
                              el.value === localStorage.getItem('defaultLang')
                          )?.id
                      )
                    ]
                )
              : t('none')
          }`}
        </p>
      </div>
    </>
  );
};

const NoofDishes = ({
  e,
  previousMeal,
  t,
  usersToDisplay,
  gridApi,
  currentLanguage,
}) => {
  const d = { ...e.data?.NoofDishes };
  const propertyValues = Object.values(d);
  let filter = propertyValues.filter((ele) => ele.isChefMealDish === true);
  return (
    <>
      <div className={'percent-data'}>
        <Tippy
          content={
            <>
              {propertyValues && (
                <div className="hover-popup">
                  {propertyValues &&
                    propertyValues.map((el) => (
                      <p>{el.name[currentLanguage.id]}</p>
                    ))}
                </div>
              )}
            </>
          }
        >
          <p className={'inventory'} id="myButton">
            {`${propertyValues ? propertyValues.length : 0}`}
          </p>
        </Tippy>
      </div>
    </>
  );
};

const ActionButton = ({ e, t, handleAddDishAll }) => {
  return (
    <div className={`action-btn-add`}>
      <button className="edit-btn" onClick={() => handleAddDishAll(e.data)}>
        {t('CopyMeal')}
      </button>
    </div>
  );
};
const ViewMore = ({ e, handleViewMore }) => {
  return (
    <div className="view-more-btn">
      <img src={next} alt="next" onClick={() => handleViewMore(e.data)} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  user: state.user,
  Roles: state.app.enums.Role,
  Languages: state.app.enums.Languages,
  UserStatuses: state.app.enums.UserStatus,
  Kitchens: state.kitchens.options,
  MealStatuses: state.app.enums.MealStatus,
  MealTypes: state.app.enums.MealType,
  currentLanguage: state.languages.current,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
};
export default connect(mapStateToProps, mapDispatchToProps)(CopyPreviousMeal);
