import React, { useEffect, useState } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
} from '../../store/app/actions';
import { connect } from 'react-redux';
import http from '../../services/http';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../services/mediaQueries';

import { StaticStars } from '../../components/RatingStars';
import { ActiveButton } from '../../components/WE-Button';
import WEIcon from '../../components/WE-IconButton';
import DishImage from '../../screens/client/DishImage';
import { useHistory, useLocation, useParams } from 'react-router';
import enums from '../../services/enums';
import { sleep } from '../../services/utils';
import dates from '../../services/dates';
// import WEBackButton from '../../components/WE-BackButton';
import { Alert } from '../../components/Dialogs/SwalDialogs';
import add from '../../assets/images/add.svg';
import Chip from '@material-ui/core/Chip';
import searchIcon from '../../assets/images/Search.svg';
import BackLink from '../../components/BackLink';
import AddEditExtraDishPopup from './AddEditExtraDishPopup';
const AddExtrasDishToMeal = ({
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  user,
  kitchen,
  MealStatuses,
  DishStatuses,
  MealDishStatuses,
  MealTypes,
  DishCategories,
  languages,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [dishes, setDishes] = useState(null);
  const { date, mealTypeId, mealId } = useParams();
  const [search, setSearch] = useState('');
  const handleSearchChange = (value) => setSearch(value);
  const [addEditPopup, setAddEditPopup] = useState(false);
  const location = useLocation();
  const { backText, backLink } = location.state ?? {};
  const [fileValue, setFileValue] = useState();
  // const [values, setValues] = useState(InitialValues);
  useEffect(() => {
    const fetchData1 = async () => {
      await useLoader(() => sleep());
    };
    fetchData1();
  }, []);
  const handleAddEdit = async (fl = false) => {
    if (fl) {
      setDummy((prev) => !prev);
    }
    setFileValue();
    setValues(InitialValues);
    setTranslations({});
    setAddEditPopup(!addEditPopup);
    setDish(null);
  };
  const MealDishStatusMap = MealDishStatuses.reduce((acc, mealDish) => {
    return { ...acc, [mealDish.value]: mealDish.id };
  }, {});
  const MealStatusMap = MealStatuses.reduce((acc, meal) => {
    return { ...acc, [meal.value]: meal.id };
  }, {});
  const DishStatusMap = DishStatuses.reduce((acc, dish) => {
    return { ...acc, [dish.value]: dish.id };
  }, {});
  const MealTypesMap = MealTypes.reduce((acc, type) => {
    return { ...acc, [type.id]: type.display };
  }, {});

  useEffect(() => {
    const fetchData1 = async () => {
      setLayoutOptions({
        title: `${t('addDishToMeal')}`,
        showDatePicker: true,
        isDatePickerClickable: false,
      });
      if (!kitchen) return;

      // Fetch both 'all dishes' and 'only in meal' dishes
      const { data: allDishes } = await useFetchingLoader(
        async () =>
          await http.get(`mealExtras/getAll`, {
            filter: {
              kitchenId: kitchen.id,
            },
          })
      );

      const allExtrasAdded = await useFetchingLoader(async () => {
        return await http.get(`mealExtras/getMealExtrasMeal/${mealId}`);
      });

      const mealDishesIds = allExtrasAdded?.map((md) => md?.id);
      const data = allDishes.map((dish) => ({
        ...dish,
        isIncluded: mealDishesIds?.includes(dish?.id),
      }));
      setDishes(data);
    };
    fetchData1();
  }, [kitchen, mealId]);

  const handleAddDish = async (extrasId) => {
    let requestValues = {
      mealExtraId: extrasId,
      mealId: Number.parseInt(mealId),
    };
    try {
      await useFetchingLoader(
        async () => await http.post(`mealExtras/addMealExtras`, requestValues)
      );
      // Update dishes, set the added dish as included
      setDishes((dishes) =>
        dishes.map((dish) =>
          dish.id === extrasId ? { ...dish, isIncluded: true } : dish
        )
      );
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  const [dishesToDisplay, setDishesToDisplay] = useState(null);
  useEffect(() => {
    let filteredDishes = dishes;
    setDishesToDisplay(filteredDishes);
  }, [dishes, search]);
  return (
    <div className={'add-dish-to-meal'}>
      <div className="container">
        <div className="search-section">
          <BackLink redirectHistory={backLink} />
          <div className="search-input-section">
            <input
              className={`input`}
              type={'text'}
              onKeyUp={(e) => handleSearchChange(e.target.value)}
              placeholder={`${t('search')}`}
            />
            <img src={searchIcon} alt="search" />
          </div>
        </div>

        <div className="add-dishes-table add-extra">
          <div className="dishes-header">
            <h6>{t('name')}</h6>
            <h6>{t('price')}</h6>
            <h6>{t('rating')}</h6>
            <h6></h6>
          </div>

          {dishesToDisplay && dishesToDisplay.length ? (
            <div className={'dishes'}>
              {dishesToDisplay
                .sort((d1, d2) => d1?.name?.localeCompare(d2?.name))
                .map((dishData) => (
                  <Dish
                    key={dishData.id}
                    dishData={dishData}
                    cb={handleAddDish}
                    DishCategories={DishCategories}
                  />
                ))}
            </div>
          ) : (
            /* If there are no dishes to display (Might be due to search filter) */
            <div className="no-dishes">
              <p>{t('noDishes')}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Dish = ({ dishData, DishCategories, cb = () => {} }) => {
  const { t } = useTranslation();
  const DishCategoriesMap = DishCategories.reduce((acc, cat) => {
    return { ...acc, [cat.id]: cat.display };
  }, {});
  return (
    <div className={'dish dish-content'}>
      <section className={'dish-image'}>
        <DishImage fileName={dishData.imageUrl} size={{ height: 100 }} />
        <p>{dishData.name}</p>
      </section>

      {/* Name and Type */}
      <section className="dish-price">
        {/* <div className="dish-name">
          {dishData.tags.map((tag) => (
            <Chip label={tag.displayName} size="small" />
          ))}
        </div> */}

        {/* <p className={'type'}>{DishCategoriesMap[dishData.dishCategoryId]}</p> */}
        <div className="dish-price">
          <p className={'type'}>₪ {dishData.price}</p>
        </div>
      </section>
      <section className={'section2'}>
        <div className={'rating'}>
          <StaticStars
            rating={dishData.rating || -1}
            numOfRaters={dishData.numOfRaters}
          />
        </div>
      </section>
      {/* Rating and Included/Add */}
      <section>
        <div className={'include-add'}>
          {dishData.isIncluded ? (
            <p>{t('includedInMeal')}</p>
          ) : (
            <img src={add} alt="add" onClick={() => cb(dishData.id)}></img>
          )}
        </div>
      </section>
      {/*<AddEditDishPopup
              show={addEditPopup}
              handleClose={handleAddEdit}
              
              languages={languages}
              
              kitchen={kitchen}
              dish={dish}
              setAddEditPopup={setAddEditPopup}
              translations={translations}
              setTranslations={setTranslations}
              tags={tags}
              setTags={setTags}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              errors={errors}
              setErrors={setErrors}
              fileValue={fileValue}
              setFileValue={setFileValue}
              setValues={setValues}
              values={values}
              InitialValues={InitialValues}
              setDummy={setDummy}
              updateData={updateData}
            />*/}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  kitchen: state.kitchens.current,
  DishCategories: state.app.enums.DishCategory,
  MealDishStatuses: state.app.enums.MealDishStatus,
  MealStatuses: state.app.enums.MealStatus,
  DishStatuses: state.app.enums.DishStatus,
  MealTypes: state.app.enums.MealType,
  languages: state.languages.options,
});
const mapDispatchToProps = { setLayoutOptions, useLoader, useFetchingLoader };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddExtrasDishToMeal);
