import React, { useEffect, useRef, useState } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  showAlert,
  getAllNotifications,
} from '../store/app/actions';
import { connect } from 'react-redux';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { useHistory } from 'react-router-dom';
import { Modal, Box, Grid, styled, Typography, Paper } from '@material-ui/core';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import WEIcon from '../components/WE-IconButton';
import { useTranslation } from 'react-i18next';
import http from '../services/http';
import { isTabletOrMobile } from '../services/mediaQueries';
import { sleep } from '../services/utils';
import { Alert, Dialog } from '../components/Dialogs/SwalDialogs';
import { ActiveButton } from '../components/WE-Button';
import cancel from '../assets/images/close.svg';
import moment from 'moment';
import JSONFormatter from 'json-formatter-js';
import search from '../assets/icons/search.png';
import file from '../assets/images/file-export.svg';
import { Button } from 'react-bootstrap';
import more from '../assets/images/more.svg';
import next from '../assets/images/next.png';
import agGridLocales from '../translations/ag-grid';
import Pagination from 'react-bootstrap/Pagination';
import Select from 'react-select';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #ededed',
  boxShadow: 24,
  padding: '35px 20px',
  p: 4,
  borderRadius: '5px',
  maxWidth: '740px',
  width: '100%',
  height: '100vh',
};

const SelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: 'auto',
    minHeight: 40,
    width: 200,
    padding: '0',
    backgroundColor: '#eff0f7',
    border: '1px solid #eff0f7',
    boxShadow: 'none',
    borderRadius: '5px',
    fontSize: '12px',
    fontFamily: 'Open Sans',
    textAlign: 'left',
    color: '#6e6893',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#6e6893',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    width: 200,
    fontSize: '12px',
    padding: '3px 10px',
    fontFamily: 'Open Sans',
    color: '#6e6893',
    textAlign: 'left',
  }),
  menu: (provided, state) => ({
    ...provided,
    width: 200,
    fontSize: '12px',
    fontFamily: 'Open Sans',
    color: '#6e6893',
    textAlign: 'left',
  }),
};
const Notifications = ({
  user,
  kitchen,
  setLayoutOptions,
  Roles,
  UserStatuses,
  Kitchens,
  useLoader,
  useFetchingLoader,
  languages,
  currentLanguage,
  getAllNotifications,
  allNotificationsToDisplay,
  notificationsTotal,
  showFetchingLoader,
  pushNotification,
  notificationsCount,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [logsToDisplay, setLogsToDisplay] = useState(null);
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [viewData, setViewData] = useState();
  const [prefetchData, setPrefetchData] = useState(null);
  const [jsonData, setJsondata] = useState();
  const [maxDates, setMaxDate] = useState(false);
  const [columns, setcolumns] = useState();
  const [details, setDetails] = useState();
  const [currentPageAllLogs, setcurrentPageAllLogs] = useState(1); // Current page
  const [newPageSizeLogs, setPageSizeLogs] = useState(25); // Records per page
  const [totalLogsRecords, setTotalLogsRecords] = useState(0); // Total records
  const [pageCountsLogs, setpageCountsLogs] = useState(1); // Total pages count
  const [columnAndOrder, setColumnAndOrder] = useState({});
  const [readStatus, setReadStatus] = useState();
  const [readStatuses] = useState([
    { value: 2, label: t('read') },
    { value: 1, label: t('unread') },
  ]);
  /***********fetch log   data */
  const fetchDataFromApi = async () => {
    let filterData = {
      companyId: user.companyId,
      page: currentPageAllLogs,
      limit: newPageSizeLogs,
      readStatusId: readStatus,
      orderField:
        'field' in columnAndOrder ? columnAndOrder?.field : 'createdAt',
      orderTarget: 'order' in columnAndOrder ? columnAndOrder?.order : 'desc',
    };
    for (const key in filterData) {
      if (
        filterData[key] === undefined ||
        filterData[key] === null ||
        filterData[key] === 'null'
      ) {
        delete filterData[key];
      }
    }
    await useFetchingLoader(async () => {
      await getAllNotifications(filterData);
    });
  };
  useEffect(() => {
    fetchDataFromApi();
  }, [
    currentPageAllLogs,
    newPageSizeLogs,
    columnAndOrder,
    readStatus,
    pushNotification,
    notificationsCount,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      setPrefetchData(allNotificationsToDisplay);
      let logsData = allNotificationsToDisplay
        ? allNotificationsToDisplay.map((u, i) => ({
            title: u.title,
            message: u.message,
            params: u.params,
            category: u.categoryId,
            name: u?.user?.firstName
              ? u?.user?.firstName + ' ' + u?.user?.lastName
              : '',
            date: new Date(u.createdAt).toISOString().slice(0, 10),
            time: moment(u.createdAt).format('hh : mm A'),
            statusId: u?.userNotifications[0]?.statusId,
            otherParams: u,
          }))
        : [];

      setLogsToDisplay(logsData);
    };
    fetchData();
  }, [allNotificationsToDisplay]);

  useEffect(() => {
    const fetchData = async () => {
      setTotalLogsRecords(allNotificationsToDisplay);
      setpageCountsLogs(Math.ceil(notificationsTotal / newPageSizeLogs));
    };
    if (allNotificationsToDisplay) {
      fetchData();
    }
  }, [allNotificationsToDisplay]);

  // ag grid data
  const columnDefs = [
    {
      field: 'title',
      headerName: `${t('title')}`,

      cellRenderer: (params) => {
        return params?.data?.title
          ? params.data?.statusId == 2
            ? `${params.data.title}`
            : `<b>${params.data.title}</b>`
          : '--';
      },
    },
    {
      field: 'message',
      headerName: t('message'),

      cellRenderer: (params) => {
        return params.data.message
          ? params.data?.statusId == 2
            ? `${params.data.message}`
            : `<b>${params.data.message}</b>`
          : '--';
      },
    },
    {
      field: 'category',
      headerName: t('category'),
      cellRenderer: (params) => {
        return params.data.category
          ? params.data?.category == 1
            ? params.data?.statusId == 2
              ? t('connectteam')
              : `<b>${t('connectteam')}</b>`
            : params.data?.category == 2
              ? params.data?.statusId == 2
                ? t('users')
                : `<b>${t('users')}</b>`
              : params.data?.statusId == 2
                ? t('system')
                : `<b>${t('system')}</b>`
          : '--';
      },
    },

    {
      field: 'name',
      headerName: t('name'),
      cellRenderer: (params) => {
        return params.data.name
          ? params.data?.statusId == 2
            ? `${params.data.name}`
            : `<b>${params.data.name}</b>`
          : '--';
      },
    },

    {
      field: 'createdAt',
      headerName: `${t('createdAt')}`,
      sortable: true,
      comparator: () => {},
      cellRenderer: (params) => {
        return params?.data?.date
          ? params.data?.statusId == 2
            ? `${params?.data?.date} <br />${params?.data?.time}`
            : `<b>${params?.data?.date} <br />${params?.data?.time}</b>`
          : '--';
      },
    },
    {
      field: 'statusId',
      headerName: t('readStatus'),
      cellRenderer: (params) => {
        return params.data.statusId
          ? params.data?.statusId == 2
            ? t('read')
            : `<div class='statusId-wrapper'><p class='statusId-text'><b>${t(
                'unread'
              )}</b></p> <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 18 18"
            fill="none"
          >
            <circle
              cx="9"
              cy="9"
              r="9"
              fill=#eb0014
            />
          </svg></div>`
          : '--';
      },
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
    },
  ];

  const tabcolumnDefs = [
    {
      field: 'title',
      headerName: `${t('title')}`,

      cellRenderer: (params) => {
        return params?.data?.title
          ? params.data?.statusId == 2
            ? `${params.data.title}`
            : `<b>${params.data.title}</b>`
          : '--';
      },
    },
    {
      field: 'message',
      headerName: t('message'),
      cellRenderer: (params) => {
        return params.data.message
          ? params.data?.statusId == 2
            ? `${params.data.message}`
            : `<b>${params.data.message}</b>`
          : '--';
      },
    },
    {
      field: 'statusId',
      headerName: t('readStatus'),
      cellRenderer: (params) => {
        return params.data.statusId
          ? params.data?.statusId == 2
            ? t('read')
            : `<div class='statusId-wrapper'><p class='statusId-text'><b>${t(
                'unread'
              )}</b></p> <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 18 18"
            fill="none"
          >
            <circle
              cx="9"
              cy="9"
              r="9"
              fill=#eb0014
            />
          </svg></div>`
          : '--';
      },
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
    },
  ];

  const mobcolumnDefs = [
    {
      field: 'message',
      headerName: t('message'),
      cellRenderer: (params) => {
        return params.data.message
          ? params.data?.statusId == 2
            ? `${params.data.message}`
            : `<b>${params.data.message}</b>`
          : '--';
      },
    },
    {
      field: 'statusId',
      headerName: t('readStatus'),
      cellRenderer: (params) => {
        return params.data.statusId
          ? params.data?.statusId == 2
            ? t('read')
            : `<div class='statusId-wrapper'><p class='statusId-text'><b>${t(
                'unread'
              )}</b></p> <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 18 18"
            fill="none"
          >
            <circle
              cx="9"
              cy="9"
              r="9"
              fill=#eb0014
            />
          </svg></div>`
          : '--';
      },
    },
    {
      field: '',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
    },
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    suppressMovable: true,
  };

  const gridOptions = {
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };
  const handleClose = (details) => {
    setViewData(false);
  };
  const handleViewData = (data) => {
    setViewData(true);
    setDetails(undefined);
    setJsondata(Object.entries(JSON.parse(data?.params)));
  };

  /***********Export CSV file */

  const handleViewMore = async (data) => {
    setViewData(true);
    setDetails(data);
    setJsondata(Object.entries(JSON.parse(data?.params)));
    if (data?.statusId !== 2) {
      await http.put(`notifications/readNotification`, {
        userId: user.id,
        notificationId: data.otherParams.id,
      });
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 479) {
      setcolumns(tabcolumnDefs);
    } else if (window.innerWidth <= 480) {
      setcolumns(mobcolumnDefs);
    } else {
      setcolumns(columnDefs);
    }
  }, []);
  const printSortStateToConsole = () => {
    const sortState = gridApi.getSortModel();
    if (sortState.length === 0) {
      setColumnAndOrder({});
    } else {
      setColumnAndOrder({
        field: sortState[0].colId,
        order: sortState[0].sort,
      });
    }
  };
  const lang = localStorage.getItem('language');
  return (
    <div className="global-data-table reservation-management log-viewer-page">
      <div className="container">
        <div className="notifications-page-action-block">
          <Select
            name="readStatus"
            placeholder={t('selectInviteStatus')}
            value={readStatuses.filter((el) => el.value == readStatus)}
            isClearable={true}
            onChange={(event) => {
              setcurrentPageAllLogs(1);
              setReadStatus(event?.value ? event?.value : undefined);
            }}
            options={readStatuses}
            styles={SelectStyles}
          />
          <Button
            onClick={async () => {
              await http.put(`notifications/readAllNotifications`, {
                userId: user.id,
              });
            }}
            className="notifications-page-read-all"
          >
            {t('readAll')}
          </Button>
        </div>

        <div
          className="ag-theme-alpine"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact
            className="ag-theme-alpine"
            rowData={logsToDisplay}
            localeText={agGridLocales[i18n.language]}
            reactUi={lang === '"he"' ? false : true}
            enableRtl={lang === '"he"' ? true : false}
            columnDefs={columns}
            domLayout={'autoHeight'}
            gridOptions={gridOptions}
            onSortChanged={() => printSortStateToConsole()}
            rowSelection="multiple"
            frameworkComponents={{
              ActionButton: (e) => (
                <ActionButton handleViewData={handleViewData} e={e} />
              ),
              ViewMore: (e) => (
                <ViewMore e={e} handleViewMore={handleViewMore} />
              ),
            }}
            debug={false}
            onGridReady={(params) => {
              setGridApi(params.api);
              setColumnApi(params.columnApi);
              params.api.sizeColumnsToFit();
              document.querySelector('.ag-pinned-left-cols-container').remove();
            }}
            onFirstDataRendered={(params) => {
              params.api.sizeColumnsToFit();
            }}
            overlayLoadingTemplate={'<p></p>'}
          ></AgGridReact>
          <div className="pagination-view we-pagination-view">
            <div className="we_show_records_main">
              <div
                style={{
                  display: 'flex',
                  flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                  direction: 'ltr',
                  gap: 5,
                }}
              >
                <p>
                  {newPageSizeLogs * (currentPageAllLogs - 1) + 1} -{' '}
                  {newPageSizeLogs * currentPageAllLogs}
                </p>
                <p>{t('of')}</p>
                <p>{notificationsTotal}</p>
                <p>{t('results')}</p>
              </div>
            </div>
            <Pagination
              style={{
                display: 'flex',
                flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
              }}
            >
              <Pagination.First
                disabled={pageCountsLogs <= 1 ? true : false}
                onClick={() => {
                  setcurrentPageAllLogs(1);
                }}
                style={{
                  transform:
                    i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
              <Pagination.Prev
                disabled={pageCountsLogs <= 1 ? true : false}
                onClick={() => {
                  currentPageAllLogs > 1
                    ? setcurrentPageAllLogs(currentPageAllLogs - 1)
                    : '';
                }}
                style={{
                  transform:
                    i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />

              <Pagination.Item active>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                    direction: 'ltr',
                    gap: 5,
                  }}
                >
                  <p style={{ margin: 0 }}>{t('page')}</p>
                  <p style={{ margin: 0 }}>{currentPageAllLogs}</p>
                  <p style={{ margin: 0 }}>{t('of')}</p>
                  <p style={{ margin: 0 }}>
                    {Math.ceil(notificationsTotal / newPageSizeLogs)}
                  </p>
                </div>
              </Pagination.Item>

              <Pagination.Next
                disabled={pageCountsLogs <= 1 ? true : false}
                onClick={() => {
                  currentPageAllLogs <
                  Math.ceil(notificationsTotal / newPageSizeLogs)
                    ? setcurrentPageAllLogs(currentPageAllLogs + 1)
                    : '';
                }}
                style={{
                  transform:
                    i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
              <Pagination.Last
                disabled={pageCountsLogs <= 1 ? true : false}
                onClick={() => {
                  setcurrentPageAllLogs(
                    Math.ceil(notificationsTotal / newPageSizeLogs)
                  );
                }}
                style={{
                  transform:
                    i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
            </Pagination>
          </div>
        </div>
        <Modal
          open={viewData}
          fullScreen={false}
          disableEnforceFocus
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          maxWidth="lg"
          className="view-more-popup"
        >
          <Box sx={style}>
            <div className="view-detail-head">
              <div className="reservation-title">
                <h5>
                  {t('notificationsDetails')}
                  {details?.userId}
                </h5>
                <h6>{details?.Name ? details?.Name : ''}</h6>
              </div>
              <div className="close-btn-view">
                <img
                  src={cancel}
                  alt="close"
                  onClick={() => handleClose(details)}
                />
              </div>
            </div>
            <Typography id="modal-modal-description">
              {details ? (
                <>
                  <div className="more-detail-popup">
                    <div className="user-detail-container">
                      <div className="user-details-row">
                        <div className="view-detail-content">
                          <div className="user-details-block">
                            <h6>{t('title')}:</h6>
                            <p>{details?.title ? details?.title : '--'}</p>
                          </div>
                          <div className="user-details-block">
                            <h6>{t('message')}:</h6>
                            <p>{details?.message ? details?.message : '--'}</p>
                          </div>
                          <div className="user-details-block">
                            <h6>{t('category')}:</h6>
                            <p>
                              {details?.category
                                ? details?.category == 1
                                  ? t('connectteam')
                                  : t('users')
                                : '--'}
                            </p>
                          </div>
                          <div className="user-details-block">
                            <h6>{t('userName')}:</h6>
                            <p>
                              {details?.otherParams?.user?.firstName
                                ? details?.otherParams?.user?.firstName +
                                  ' ' +
                                  details?.otherParams?.user?.lastName
                                : '--'}
                            </p>
                          </div>
                          <div className="user-details-block">
                            <h6>{t('employeeNo')}:</h6>
                            <p>
                              {details?.otherParams?.user?.employeeId
                                ? details?.otherParams?.user?.employeeId
                                : '--'}
                            </p>
                          </div>
                          <div className="user-details-block">
                            <h6>{t('cardId')}:</h6>
                            <p>
                              {details?.otherParams?.user?.cardId
                                ? details?.otherParams?.user?.cardId
                                : '--'}
                            </p>
                          </div>
                          <div className="user-details-block">
                            <h6>{t('createdAt')}:</h6>
                            <p>
                              {details?.date
                                ? details?.date + ' ' + details?.time
                                : '--'}
                            </p>
                          </div>
                          <Accordion
                            sx={{
                              border: '1px solid #333951',
                              borderRadius: '8px !important',
                              width: '100%',
                              marginTop: '5px',
                            }}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              variant="primary"
                            >
                              <Typography>{t('param')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {jsonData
                                ? jsonData.map((el) => {
                                    if (
                                      el[0] === 'Filename' ||
                                      el[0] === 'File Name'
                                    ) {
                                      return (
                                        <div className="user-details-block user-details-block-custom-flex-settings">
                                          <h6>{el[0]}:</h6>
                                          <p
                                            onClick={() => {
                                              const fileUrl = jsonData.find(
                                                (el) =>
                                                  el[0] === 'File' ||
                                                  el[0] === 'File Url'
                                              )[1];
                                              const link =
                                                document.createElement('a');
                                              link.href = fileUrl;
                                              link.download = el[0];
                                              document.body.appendChild(link);
                                              link.click();
                                              document.body.removeChild(link);
                                            }}
                                            className="user-details-block-file-download-text"
                                          >
                                            {el[1] ? el[1] : '--'}
                                          </p>
                                        </div>
                                      );
                                    } else if (
                                      el[0] !== 'File' &&
                                      el[0] !== 'File Url'
                                    ) {
                                      return (
                                        <div className="user-details-block user-details-block-custom-flex-settings">
                                          <h6>{el[0]}:</h6>
                                          <p>{el[1] ? el[1] : '--'}</p>
                                        </div>
                                      );
                                    } else {
                                    }
                                  })
                                : ''}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="more-detail-popup">
                  <div className="user-detail-container">
                    <div className="user-details-row">
                      <div className="view-detail-content">
                        <Accordion
                          sx={{
                            border: '1px solid #333951',
                            borderRadius: '8px !important',
                            width: '100%',
                            marginTop: '5px',
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            variant="primary"
                          >
                            <Typography>{t('param')}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <p>
                              <ul className="user-details-block-list-wrapper">
                                {jsonData
                                  ? jsonData.map((el) => (
                                      <li className="user-details-block-list-elem">{`${el[0]}: ${el[1]}`}</li>
                                    ))
                                  : ''}
                              </ul>
                            </p>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Typography>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

const ViewMore = ({ e, handleViewMore }) => {
  return (
    <div className="view-more-btn">
      <img src={next} alt="next" onClick={() => handleViewMore(e.data)} />
    </div>
  );
};

const ConfirmationPopup = ({
  open,
  handleClose,
  handleDeleteUser,
  prefetchData,
  jsonData,
  details,
  t,
}) => {
  useEffect(() => {}, [details, jsonData]);
  return <></>;
};

const ActionButton = ({ e, handleViewData, t }) => {
  return (
    <div className={`log-btn`}>
      <Button onClick={() => handleViewData(e.data)}>View Data</Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  kitchen: state.kitchens.current,
  user: state.user,
  Roles: state.app.enums.Role,
  UserStatuses: state.app.enums.UserStatus,
  Kitchens: state.kitchens.options,
  languages: state.languages.options,
  currentLanguage: state.languages.current,
  allNotificationsToDisplay: state.app.allNotificationsToDisplay,
  notificationsTotal: state.app.notificationsTotal,
  showFetchingLoader: state.app.showFetchingLoader,
  pushNotification: state.app.pushNotification,
  notificationsCount: state.app.notificationsCount,
});

const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  showAlert,
  getAllNotifications,
};
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
