import React, { useEffect, useState, useRef } from 'react';
import {
  setLayoutOptions,
  useFetchingLoader,
  useLoader,
  currentMealsData,
  setOpenCopyMealPopup,
  setISuccessCopyMealPopup,
} from '../../store/app/actions';
import { connect } from 'react-redux';
import http from '../../services/http';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../services/mediaQueries';

import { StaticStars } from '../../components/RatingStars';
import { ActiveButton } from '../../components/WE-Button';
import WEIcon from '../../components/WE-IconButton';
import DishImage from '../../screens/client/DishImage';
import { useHistory } from 'react-router';
import enums from '../../services/enums';
import dates from '../../services/dates';
import { sleep } from '../../services/utils';
import ShowPreviousContainer from '../../components/ShowPrevious';
import moment from 'moment';
import { sortCurrentMeals } from '../../services/sortItems';
import { Alert, Dialog } from '../../components/Dialogs/SwalDialogs';
import WECheckbox from '../../components/Forms/WE-CheckBox';
import MealSummary from '../../components/Kitchen/MealSummary';
import WEToggle from '../../components/WE-Toggle';
import DatePicker from 'react-datepicker';
import { Button } from 'react-bootstrap';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Stepper,
  Step,
  StepLabel,
  Box,
  DialogContentText,
} from '@mui/material';
import MuiDialog from '@mui/material/Dialog';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import ViewCurrentMealDetail from './ViewCurrentMealDetail';
import next from '../../assets/images/next.png';
import agGridLocales from '../../translations/ag-grid';
import Pagination from 'react-bootstrap/Pagination';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import {
  Theme,
  useTheme,
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const MealManagement = ({
  setLayoutOptions,
  user,
  kitchen,
  MealTypes,
  MealStatuses,
  useLoader,
  useFetchingLoader,
  MealDishStatuses,
  currentLanguage,
  setOpenCopyMealPopup,
  setISuccessCopyMealPopup,
  meals,
  mealsToDisplay,
  currentMealsData,
  dataTableData,
  mealsTotal,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    const fetchData1 = async () => {
      await useLoader(() => sleep());
    };
    fetchData1();
  }, []);
  const MealDishStatusMap = MealDishStatuses.reduce((acc, dish) => {
    return { ...acc, [dish.value]: dish.id };
  }, {});

  const today = dates.formatServer(new Date());
  // const [meals, setMeals] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['fromDateTitle', 'toDateTitle', 'confirmTitle'];
  const [open, setOpen] = useState(false);
  const [fromDate, setFromDate] = useState({
    first: new Date().setDate(new Date().getDate() - 6),
    second: new Date().setDate(new Date().getDate()),
  });
  const [toDate, setToDate] = useState({
    first: new Date().setDate(new Date().getDate() - 6),
    second: new Date().setDate(new Date().getDate()),
  });
  const resetErrConfig = {
    dateError: false,
    dateRangeNotMatch: false,
    dateRangeTooBig: false,
    dateRangesIntersect: false,
    mealsNotSelect: false,
  };
  const [errors, setErrors] = useState(resetErrConfig);
  const [allmealsToDisplay, setMealsToDisplay] = useState(null);
  const [showPrevious, setShowPrevious] = useState(false);
  const [futureData, setFutureData] = useState(true);
  const [gridApi, setGridApi] = useState();
  const [columnApi, setColumnApi] = useState();
  const [tableColumn, setTableColumn] = useState();
  const [newPageSizeMeals, setPageSizeMeals] = useState(25); // Records per page
  const [totalMealsRecords, settotalMealsRecords] = useState(0); // Total records
  const [pageCountsMeals, setpageCountsMeals] = useState(1); // Total pages count
  const [currentPageMeals, setCurrentPageMeals] = useState(1); // Current page
  const [searchKeyword, setSearchKeywordMeals] = useState(''); // Search text
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [selectMealType, setSelectMealType] = useState(0);
  const [mealTypesForDropdown, setMealTypesForDropdown] = useState([]);
  const [selectedMeals, setSelectedMeals] = useState([]);
  const [fromTest, setFromTest] = useState(
    new Date().setDate(new Date().getDate())
  );
  const [toTest, setToTest] = useState(
    new Date().setDate(new Date().getDate())
  );
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const theme = useTheme();
  const MealStatusMap = MealStatuses.reduce((acc, meal) => {
    return { ...acc, [meal.value]: meal.id };
  }, {});
  const themeRadiobuttons = createTheme({
    palette: {
      primary: {
        main: '#14CD31', // Change this to your desired color
      },
    },
  });
  const handleChangeRadiobuttons = (event) => {
    setSelectedMeals([]);
    setErrors((prev) => {
      return { ...prev, mealsNotSelect: false };
    });
    setSelectMealType(Number(event.target.value));
  };

  const handleChangeDropdown = (event) => {
    setErrors((prev) => {
      return { ...prev, mealsNotSelect: false };
    });
    const {
      target: { value },
    } = event;
    setSelectedMeals(typeof value === 'string' ? value.split(',') : value);
  };

  function getStyles(elem, selectedMeals) {
    return {
      fontWeight:
        selectedMeals.find((el) => el.id === elem.id) === undefined
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const lang = localStorage.getItem('language');
  const [viewDetails, setViewDetails] = useState();
  const [details, setDetails] = useState();
  const MealTypesMap = MealTypes.reduce((acc, meal) => {
    return { ...acc, [meal.id]: meal.display };
  }, {});

  const dateValidation = (date1, date2) => {
    const firstDate = moment(new Date(date1).toISOString().substring(0, 10));
    const secondDate = moment(new Date(date2).toISOString().substring(0, 10));

    return firstDate.isSame(secondDate) || secondDate.isAfter(firstDate);
  };

  const countAmountDaysInDateRange = (date1, date2) => {
    const startDate = moment(
      new Date(date1).toISOString().substring(0, 10),
      'YYYY-MM-DD'
    );
    const endDate = moment(
      new Date(date2).toISOString().substring(0, 10),
      'YYYY-MM-DD'
    );
    const dateArray = [];
    const currentDate = startDate.clone();
    while (currentDate.isSameOrBefore(endDate)) {
      dateArray.push(currentDate.format('YYYY-MM-DD'));
      currentDate.add(1, 'days');
    }
    return dateArray.length;
  };

  const handleChangeDatepicker = (name, value, id = 0) => {
    setErrors(resetErrConfig);
    if (name === 'fromdate') {
      if (!value) {
        setFromDate({ first: null, second: null });
      }
      if (id == 0) {
        setFromDate((prev) => {
          return { ...prev, first: value };
        });

        const toDateSecondValue = new Date(
          moment(new Date(toDate.first)).add(
            countAmountDaysInDateRange(value, fromDate.second) - 1,
            'days'
          )
        );

        setToDate((prev) => {
          return { first: new Date(toDate.first), second: toDateSecondValue };
        });

        setErrors({
          dateRangeTooBig:
            countAmountDaysInDateRange(value, fromDate.second) - 1 > 28,
          dateError: !dateValidation(value, fromDate.second),
        });
      } else {
        setFromDate((prev) => {
          return { ...prev, second: new Date(value) };
        });

        const toDateSecondValue = new Date(
          moment(new Date(toDate.first)).add(
            countAmountDaysInDateRange(fromDate.first, value) - 1,
            'days'
          )
        );

        setToDate((prev) => {
          return { first: new Date(toDate.first), second: toDateSecondValue };
        });

        setErrors({
          dateRangeTooBig:
            countAmountDaysInDateRange(fromDate.first, value) - 1 > 28,
          dateError: !dateValidation(fromDate.first, value),
        });
      }
    } else if (name === 'todate') {
      if (!value) {
        setToDate({ first: null, second: null });
      } else {
        const toDateSecondValue = new Date(
          moment(new Date(value)).add(
            countAmountDaysInDateRange(fromDate.first, fromDate.second) - 1,
            'days'
          )
        );

        setToDate((prev) => {
          return { first: new Date(value), second: toDateSecondValue };
        });
        setErrors({
          dateError: !dateValidation(value, toDateSecondValue),
        });
      }
    }
  };

  const fetchDataFromAPI = async () => {
    const filterPayload = {
      filter: {
        kitchenId: kitchen.id,
        mealStatusId: [MealStatusMap.active, MealStatusMap.blocked],
      },
      limit: newPageSizeMeals,
      page: currentPageMeals,
      order: [['date', 'ASC']],
      meta: true,
    };
    await useFetchingLoader(async () => {
      await currentMealsData(filterPayload);
    });
  };

  useEffect(() => {
    const fetchData1 = async () => {
      if (!kitchen) return;
      setLayoutOptions({ title: `${t('mealManagement')} ${kitchen.name}` });
      await fetchDataFromAPI();
    };
    fetchData1();
  }, [kitchen, currentPageMeals, newPageSizeMeals]);

  useEffect(() => {
    setMealTypesForDropdown(
      MealTypes.filter((el) => el.kitchenId === kitchen.id).map((elem) => {
        return { id: elem.id, label: elem.display };
      })
    );
  }, [kitchen]);

  useEffect(() => {
    if (dataTableData) {
      setMealsToDisplay(sortCurrentMeals(dataTableData));

      setpageCountsMeals(
        mealsTotal === 0 ? 0 : Math.ceil(mealsTotal / newPageSizeMeals)
      );
    }
  }, [dataTableData]);

  const handleMoreInfo = (mealData) => {
    let type = mealData.mealTypeId;
    let date = dates.formatServer(mealData.date);
    let mealId = mealData.mealId;
    history.push({
      pathname: `/menuConstruction/${date}/${type}`,
      state: { backText: t('returnToMealManagement'), mealID: mealId },
    });
  };

  const handleDeleteMeal = async (mealData) => {
    let mealId = mealData.mealId;
    try {
      await useFetchingLoader(async () => {
        await http.post(`meals/${mealId}/deleteMeal`);
      });
      setViewDetails(false);
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  const MealsCallBacks = {
    moreInfo: handleMoreInfo,
    delete: handleDeleteMeal,
  };

  const handleSubmit = async () => {
    try {
      setSelectedMeals([]);
      setSelectMealType(0);
      setOpen(false);
      setConfirmDialog(true);
      await http.post('mealsDishes/copyPreviousWeek', {
        dateFrom: moment(fromDate.first).format('YYYY-MM-DD'),
        dateTo: moment(fromDate.second).format('YYYY-MM-DD'),
        copyDateFrom: moment(toDate.first).format('YYYY-MM-DD'),
        copyDateTo: moment(toDate.second).format('YYYY-MM-DD'),
        kitchenId: kitchen.id,
        mealTypes: selectedMeals.map((elem) => elem.id),
      });
      if (window.location.hash.includes('mealManagement')) {
        await fetchDataFromAPI();
      }
      setISuccessCopyMealPopup(true);
      setOpenCopyMealPopup(true);
    } catch (e) {
      setISuccessCopyMealPopup(false);
      setOpenCopyMealPopup(true);
    }
  };

  const handleBlockMeal = async (mealId, mealStatusId) => {
    let changeToStatus =
      MealDishStatusMap.active === mealStatusId
        ? MealDishStatusMap.blocked
        : MealDishStatusMap.active;
    try {
      await useFetchingLoader(
        async () =>
          await http.put(`mealsDishes/updateByMealId/${mealId}`, {
            mealStatusId: changeToStatus,
          })
      );
      // setMealsToDisplay((state) =>
      //   state.map((mealDish) =>
      //     mealDish.id === mealId
      //       ? { ...mealDish, mealStatusId: changeToStatus }
      //       : mealDish
      //   )
      // );
    } catch (err) {
      setTimeout(() => Alert({ error: err }), 150);
      return err;
    }
  };

  const handleRatingPage = (mealData) => {
    history.push({
      pathname: `/allReviews/${mealData.date}/${mealData.id}`,
    });
  };

  const handleViewMore = (data) => {
    setDetails(data);
  };

  const columnDefs = [
    {
      field: 'mealDate',
      headerName: t('date'),
      sortable: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.mealDate}`;
      },
    },
    {
      field: 'dayOfWeek',
      headerName: t('dayOfWeek'),
      cellRenderer: (params) => {
        return `${moment(params.data.date).format('dddd')}`;
      },
    },
    {
      field: 'mealFrom',
      headerName: t('time'),
      suppressMovable: true,
      cellRenderer: (params) => {
        return params.data.mealFrom + ' - ' + params.data.mealTo;
      },
    },
    {
      field: 'mealType',
      headerName: t('mealType'),
      suppressMovable: true,
    },
    {
      field: 'title',
      headerName: t('title'),
      suppressMovable: true,
      cellRenderer: (params) => {
        return params.data.title || '--';
      },
    },
    {
      field: 'promote',
      headerName: t('promote'),
      suppressMovable: true,
      cellRenderer: (params) => {
        return params.data.promote ? t('yes') : t('no') ?? '--';
      },
    },
    {
      field: 'mealInventory',
      headerName: t('tInventory'),
      suppressMovable: true,
    },
    {
      field: 'mealOrdered',
      headerName: t('ordered'),
      suppressMovable: true,
      cellRenderer: 'ReservationsInventory',
    },
    {
      field: 'mealRealized',
      headerName: t('realizedOrder'),
      suppressMovable: true,
      cellRenderer: 'RealizedReservations',
    },
    {
      field: 'Action',
      headerName: '',
      headerClass: 'resizable-header',
      suppressMovable: true,
      cellRenderer: 'ActionButton',
    },
  ];

  const columnDefsTab = [
    {
      field: 'mealDate',
      headerName: t('date'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${params.data.mealDate}`;
      },
    },
    {
      field: 'dayOfWeek',
      headerName: t('dayOfWeek'),
      suppressMovable: true,
      cellRenderer: (params) => {
        return `${moment(params.data.date).format('dddd')}`;
      },
    },
    {
      field: 'mealTime',
      headerName: t('time'),
      suppressMovable: true,
    },
    {
      field: 'mealType',
      headerName: t('mealType'),
      suppressMovable: true,
    },
    {
      field: 'mealInventory',
      headerName: t('tInventory'),
      suppressMovable: true,
    },
    {
      field: 'mealOrdered',
      headerName: t('ordered'),
      suppressMovable: true,
      cellRenderer: 'ReservationsInventory',
    },
    {
      field: 'mealRealized',
      headerName: t('realizedOrder'),
      suppressMovable: true,
      cellRenderer: 'RealizedReservations',
    },
    /*{
      field: '',
      headerName: '',
      headerClass: 'resizable-header',
      suppressMovable: true,
      cellRenderer: 'ActionButton',
      width: 40,
    },*/
    {
      // field: 'hghgh',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 40,
    },
  ];

  /**** mobile view column */
  const columnDefsMob = [
    {
      field: 'mealDate',
      cellRenderer: (params) => {
        return `${params.data.mealDate}`;
      },
      headerName: t('date'),
      sortable: true,
      filter: true,
      suppressMovable: true,
      width: 60,
    },
    {
      field: 'mealType',
      headerName: t('mealType'),
      suppressMovable: true,
      width: 80,
    },
    {
      field: 'mealInventory',
      headerName: t('tInventory'),
      suppressMovable: true,
      width: 50,
    },
    {
      field: 'mealOrdered',
      headerName: t('ordered'),
      suppressMovable: true,
      width: 50,
      cellRenderer: 'ReservationsInventory',
    },
    {
      field: 'mealRealized',
      headerName: t('realized'),
      suppressMovable: true,
      width: 50,
    },
    // {
    //   field: 'mealRating',
    //   headerName: t('rating'),
    //   sortable: true,
    //   filter: true,
    //   suppressMovable: true,
    //   cellRenderer: 'RatingStar',
    //   width: 100,
    // },
    // {
    //   field: '',
    //   headerName: 'delete',
    //   headerClass: 'resizable-header',
    //   suppressMovable: true,
    //   cellRenderer: 'ActionButton',
    //   width: 40,
    // },
    {
      // field: 'hghgh',
      headerName: '',
      cellRenderer: 'ViewMore',
      headerClass: 'resizable-header',
      suppressMovable: true,
      width: 40,
    },
  ];

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    suppressMovable: true,
  };

  const gridOptions = {
    rowHeight: 60,
    groupHeaderHeight: 60,
    headerHeight: 60,
    rowStyle: {
      'border-bottom': 'white 10px solid',
      'border-top': 'white 0px solid',
      'border-radius': '5px',
      padding: '0px 5px',
    },
  };

  useEffect(() => {
    if (window.innerWidth <= 1024 && window.innerWidth >= 479) {
      setTableColumn(columnDefsTab);
    } else if (window.innerWidth <= 480) {
      setTableColumn(columnDefsMob);
    } else {
      setTableColumn(columnDefs);
    }
  }, []);

  const goMenuConstruction = () =>
    history.push({
      pathname: `/menuConstruction`,
      state: { backText: t('returnToMealManagement') },
    });

  const dateRangeComparator = (dateFrom, dateTo, copyDateFrom, copyDateTo) => {
    const amountDaysInFirstRange = countAmountDaysInDateRange(
      moment(dateFrom, 'YYYY-MM-DD'),
      moment(dateTo, 'YYYY-MM-DD')
    );
    const amountDaysInSecondRange = countAmountDaysInDateRange(
      moment(copyDateFrom, 'YYYY-MM-DD'),
      moment(copyDateTo, 'YYYY-MM-DD')
    );
    return amountDaysInFirstRange !== amountDaysInSecondRange;
  };

  const dateRangeIntersectCheck = (
    dateFrom,
    dateTo,
    copyDateFrom,
    copyDateTo
  ) => {
    const startDate = moment(dateFrom, 'YYYY-MM-DD');
    const endDate = moment(dateTo, 'YYYY-MM-DD');
    const dateArray = [];
    const currentDate = startDate.clone();
    while (currentDate.isSameOrBefore(endDate)) {
      dateArray.push(currentDate.format('YYYY-MM-DD'));
      currentDate.add(1, 'days');
    }
    return dateArray.includes(copyDateFrom) || dateArray.includes(copyDateTo);
  };

  const datePastComparator = (fromDate2, toDate1) => {
    const firstDate = moment(fromDate2, 'YYYY-MM-DD');
    const secondDate = moment(toDate1, 'YYYY-MM-DD');
    const dateNow = moment(
      new Date().toISOString().substring(0, 10),
      'YYYY-MM-DD'
    );
    return firstDate.isBefore(dateNow) || firstDate.isBefore(secondDate);
  };

  const handleNext = async () => {
    setFromDate((prevFromDate) => {
      setToDate((prevToDate) => {
        if (activeStep === 1) {
          if (!dateValidation(toDate.first, toDate.second)) {
            setErrors({
              dateError: true,
            });
          } else if (
            dateRangeComparator(
              moment(new Date(prevFromDate.first)).format('YYYY-MM-DD'),
              moment(new Date(new Date(prevFromDate.second))).format(
                'YYYY-MM-DD'
              ),
              moment(new Date(prevToDate.first)).format('YYYY-MM-DD'),
              moment(new Date(prevToDate.second)).format('YYYY-MM-DD')
            )
          ) {
            setErrors((prev) => {
              return {
                ...prev,
                dateRangeNotMatch: true,
              };
            });
          } else if (
            dateRangeIntersectCheck(
              moment(new Date(prevFromDate.first)).format('YYYY-MM-DD'),
              moment(new Date(new Date(prevFromDate.second))).format(
                'YYYY-MM-DD'
              ),
              moment(new Date(prevToDate.first)).format('YYYY-MM-DD'),
              moment(new Date(prevToDate.second)).format('YYYY-MM-DD')
            )
          ) {
            setErrors((prev) => {
              return { ...prev, dateRangesIntersect: true };
            });
          } else if (
            datePastComparator(
              moment(new Date(prevToDate.first)).format('YYYY-MM-DD'),
              moment(new Date(new Date(prevFromDate.second))).format(
                'YYYY-MM-DD'
              )
            )
          ) {
            Dialog({
              title: t('copyPastDateTitle'),
              text: `${t('copyPastText')}`,
              buttons: {
                confirmButtonText: t('ok'),
                cancelButtonText: t('cancel'),
              },
              onConfirm: () => setActiveStep((prev) => prev + 1),
              onCancel: () => setActiveStep((prev) => prev),
            });
          } else {
            setErrors((prev) => {
              return resetErrConfig;
            });
            setActiveStep((prev) => prev + 1);
          }
        } else if (activeStep === 0) {
          if (!dateValidation(fromDate.first, fromDate.second)) {
            setErrors({
              dateError: true,
            });
            setActiveStep((prev) => prev);
          } else if (selectedMeals.length === 0 && selectMealType === 1) {
            setErrors((prev) => {
              return { ...prev, mealsNotSelect: true };
            });
            setActiveStep((prev) => prev);
          } else {
            const toDateSecondValue = new Date(
              moment(new Date(toDate.first)).add(
                countAmountDaysInDateRange(fromDate.first, fromDate.second) - 1,
                'days'
              )
            );
            setToDate((prev) => {
              return { first: prev.first, second: toDateSecondValue };
            });
            setActiveStep((prev) => prev + 1);
          }
        } else {
          setActiveStep((prev) => prev + 1);
        }

        return prevToDate;
      });
      return prevFromDate;
    });
  };

  const handleBack = () => {
    setErrors({
      dateError: false,
    });
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const paperStyle = {
    overflow: 'visible',
  };
  return (
    <div
      className={'meal-management current-meal-management global-data-table'}
    >
      <div className="container">
        {user.roleId !== 9 && user.roleId !== 10 && (
          <div className={'top d-flex gap-4'}>
            <Button onClick={goMenuConstruction} className="min-width-for-btns">
              <i class="las la-plus" />
            </Button>
            <Button
              onClick={() => {
                setErrors({
                  dateError: false,
                  dateRangeNotMatch: false,
                  dateRangesIntersect: false,
                });
                setToDate({
                  first: new Date().setDate(new Date().getDate() - 6),
                  second: new Date().setDate(new Date().getDate()),
                });
                setFromDate({
                  first: new Date().setDate(new Date().getDate() - 6),
                  second: new Date().setDate(new Date().getDate()),
                });
                setActiveStep(0);
                setOpen(true);
              }}
              className="min-width-for-btns"
            >
              <i class="las la-copy" />
            </Button>
          </div>
        )}

        <div className="add-dishes-table">
          <AgGridReact
            className="ag-theme-alpine"
            rowData={allmealsToDisplay}
            columnDefs={tableColumn}
            localeText={agGridLocales[i18n.language]}
            reactUi={lang === '"he"' ? false : true}
            enableRtl={lang === '"he"' ? true : false}
            frameworkComponents={{
              RatingStar: (e) => <RatingStar e={e} />,
              ReservationsInventory: (e) => <ReservationsInventory e={e} />,
              RealizedReservations: (e) => <RealizedReservations e={e} />,
              ActionButton: (e) => (
                <ActionButton
                  e={e}
                  t={t}
                  MealsCallBacks={MealsCallBacks}
                  isFuture={futureData}
                  MealTypesMap={MealTypesMap}
                  dates={dates}
                />
              ),
              ViewMore: (e) => (
                <ViewMore
                  e={e}
                  handleViewMore={handleViewMore}
                  MealsCallBacks={MealsCallBacks}
                />
              ),
            }}
            domLayout={'autoHeight'}
            gridOptions={gridOptions}
            onGridReady={(params) => {
              setGridApi(params.api);
              setColumnApi(params.columnApi);
            }}
            onFirstDataRendered={(params) => {
              params.api.sizeColumnsToFit();
            }}
            debug={false}
            overlayLoadingTemplate={'<p></p>'}
            rowSelection={'multiple'}
            rowGroupPanelShow={'always'}
            pivotPanelShow={'always'}
            enableRangeSelection={true}
            // onRowClicked={(e) => MealsCallBacks.moreInfo(e.data)}
          ></AgGridReact>
          <div className="pagination-view we-pagination-view">
            <div className="we_show_records_main">
              <div
                style={{
                  display: 'flex',
                  flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                  direction: 'ltr',
                  gap: 5,
                }}
              >
                <p>
                  {newPageSizeMeals * (currentPageMeals - 1) + 1} -{' '}
                  {newPageSizeMeals * currentPageMeals}
                </p>
                <p>{t('of')}</p>
                <p>{mealsTotal}</p>
                <p>{t('results')}</p>
              </div>
            </div>
            <Pagination
              style={{
                display: 'flex',
                flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
              }}
            >
              <Pagination.First
                disabled={pageCountsMeals <= 1 ? true : false}
                onClick={() => {
                  setCurrentPageMeals(1);
                }}
                style={{
                  transform:
                    i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
              <Pagination.Prev
                disabled={pageCountsMeals <= 1 ? true : false}
                onClick={() => {
                  currentPageMeals > 1
                    ? setCurrentPageMeals(currentPageMeals - 1)
                    : '';
                }}
                style={{
                  transform:
                    i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />

              <Pagination.Item active>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                    direction: 'ltr',
                    gap: 5,
                  }}
                >
                  <p style={{ margin: 0 }}>{t('page')}</p>
                  <p style={{ margin: 0 }}>{currentPageMeals}</p>
                  <p style={{ margin: 0 }}>{t('of')}</p>
                  <p style={{ margin: 0 }}>
                    {Math.ceil(mealsTotal / newPageSizeMeals)}
                  </p>
                </div>
              </Pagination.Item>

              <Pagination.Next
                disabled={pageCountsMeals <= 1 ? true : false}
                onClick={() => {
                  currentPageMeals < Math.ceil(mealsTotal / newPageSizeMeals)
                    ? setCurrentPageMeals(currentPageMeals + 1)
                    : '';
                }}
                style={{
                  transform:
                    i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
              <Pagination.Last
                disabled={pageCountsMeals <= 1 ? true : false}
                onClick={() => {
                  setCurrentPageMeals(Math.ceil(mealsTotal / newPageSizeMeals));
                }}
                style={{
                  transform:
                    i18n.dir() === 'rtl' ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
            </Pagination>
          </div>
        </div>
        <ViewCurrentMealDetail
          open={viewDetails}
          details={details}
          handleClose={handleViewMore}
          handleRatingPage={handleRatingPage}
          MealsCallBacks={MealsCallBacks}
          isFuture={futureData}
        />

        <MuiDialog
          open={open}
          PaperProps={{ style: paperStyle }}
          disableEnforceFocus
          fullScreen={window.innerWidth < 480}
          onClose={() => {
            setOpen(false);
            setSelectMealType(0);
            setSelectedMeals([]);
          }}
        >
          <DialogTitle
            sx={
              i18n.language === 'he'
                ? {
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }
                : {
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }
            }
          >
            <Box
              sx={{
                fontWeight: 600,
                fontSize: '22px',
              }}
            >
              {t('copyMeals')}
            </Box>
            <Box
              sx={{
                margin: '0 0 0 auto',
              }}
            >
              <IconButton
                onClick={() => {
                  setOpen(false);
                  setSelectMealType(0);
                  setSelectedMeals([]);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{ transform: i18n.language === 'he' ? 'scaleX(-1)' : 'none' }}
            >
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                style={{ direction: 'ltr' }}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel
                      sx={{
                        transform:
                          i18n.language === 'he' ? 'scaleX(-1)' : 'none',
                      }}
                      StepIconProps={{
                        style: {
                          color: index <= activeStep ? '#14cd30' : 'gray',
                        },
                      }}
                    ></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <div className="reservation-management global-data-table reservation-updated-page">
              <div className="container">
                <div className="add-employee-btn margin-bottom-10">
                  <div className="left-filter width-100 left-filter-salary-small">
                    <div className="filter-section">
                      <div className="search-filter">
                        {activeStep === 0 && (
                          <div className="flex-column">
                            <p className="custom-font-weight fs-5 mb-0 mt-2">
                              {t('copyMealFromTitle')}
                            </p>
                            <div className="flex flex-row gap-4 justify-content-center mt-4">
                              <div className="xyz">
                                <div className="from-date from-date-small">
                                  <label>{t('fromDate')}</label>
                                  <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={
                                      fromDate.first
                                        ? fromDate.first
                                        : new Date()
                                    }
                                    name="fromDate"
                                    onChange={(event) =>
                                      handleChangeDatepicker(
                                        'fromdate',
                                        event,
                                        0
                                      )
                                    }
                                    placeholder="DD/MM/YYYY"
                                  />
                                </div>
                              </div>
                              <div className="xyz">
                                <div className="from-date from-date-small">
                                  <label>{t('toDate')}</label>
                                  <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={
                                      fromDate.second
                                        ? fromDate.second
                                        : new Date()
                                    }
                                    name="toDate"
                                    onChange={(event) =>
                                      handleChangeDatepicker(
                                        'fromdate',
                                        event,
                                        1
                                      )
                                    }
                                    placeholder="DD/MM/YYYY"
                                  />
                                </div>
                              </div>
                            </div>
                            <Box sx={{ mt: 3 }}>
                              <ThemeProvider theme={themeRadiobuttons}>
                                <FormControl>
                                  <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={selectMealType}
                                    onChange={handleChangeRadiobuttons}
                                  >
                                    <FormControlLabel
                                      value={0}
                                      sx={{ textTransform: 'none !important' }}
                                      control={<Radio />}
                                      label={t('allMealTypes')}
                                    />
                                    <FormControlLabel
                                      value={1}
                                      sx={{ textTransform: 'none !important' }}
                                      control={<Radio />}
                                      label={t('specificMealTypes')}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </ThemeProvider>
                              {Boolean(selectMealType) && (
                                <FormControl
                                  sx={{ mt: 2, minWidth: 250, maxWidth: 300 }}
                                >
                                  <InputLabel
                                    id="demo-multiple-chip-label"
                                    className={'copy-meal-label'}
                                  >
                                    {t('selectMealTypes')}
                                  </InputLabel>
                                  <Select
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    className="custom-active-select"
                                    multiple
                                    value={selectedMeals}
                                    label={t('selectMealTypes')}
                                    onChange={handleChangeDropdown}
                                    input={
                                      <OutlinedInput
                                        id="select-multiple-chip"
                                        label={t('selectMealTypes')}
                                      />
                                    }
                                    sx={
                                      i18n.language === 'he'
                                        ? {
                                            textAlign: 'right',
                                            '& .MuiSelect-select': {
                                              textAlign: 'right',
                                            },
                                          }
                                        : {}
                                    }
                                    renderValue={(selected) => (
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          flexWrap: 'wrap',
                                          gap: 0.5,
                                        }}
                                      >
                                        {selected.map((value) => {
                                          return (
                                            <Chip
                                              key={value.id}
                                              label={value?.label}
                                            />
                                          );
                                        })}
                                      </Box>
                                    )}
                                    MenuProps={MenuProps}
                                  >
                                    {mealTypesForDropdown.map((elem) => (
                                      <MenuItem
                                        key={elem.id}
                                        value={elem}
                                        style={getStyles(elem, selectedMeals)}
                                      >
                                        {elem.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              )}
                            </Box>
                          </div>
                        )}
                        {activeStep === 1 && (
                          <div className="flex-column">
                            <p className="custom-font-weight fs-5 mb-0 mt-2 w-75 text-center flex justify-content-center mx-auto">{`${t(
                              'copyMealToTitle'
                            )} \n ${moment(fromDate.first).format(
                              'YYYY-MM-DD'
                            )} - ${moment(fromDate.second).format(
                              'YYYY-MM-DD'
                            )}`}</p>
                            <div className="flex flex-row gap-4 justify-content-center mt-4">
                              <div className="xyz">
                                <div className="from-date from-date-small">
                                  <label>{t('fromDate')}</label>
                                  <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={
                                      toDate.first ? toDate.first : new Date()
                                    }
                                    name="fromDate"
                                    onChange={(event) =>
                                      handleChangeDatepicker('todate', event)
                                    }
                                    placeholder="DD/MM/YYYY"
                                  />
                                </div>
                              </div>
                              <div className="xyz">
                                <div className="from-date from-date-small">
                                  <label>{t('toDate')}</label>
                                  <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={
                                      toDate.second ? toDate.second : new Date()
                                    }
                                    disabled
                                    name="toDate"
                                    placeholder="DD/MM/YYYY"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {activeStep === 2 && (
                          <div className="flex-column max-width-450">
                            <p className="custom-font-weight fs-5 mb-0 mt-2 mx-auto">{`${t(
                              'copyMealFromText'
                            )} ${moment(fromDate.first).format(
                              'YYYY-MM-DD'
                            )} - ${moment(fromDate.second).format(
                              'YYYY-MM-DD'
                            )} ${t('to')} ${moment(toDate.first).format(
                              'YYYY-MM-DD'
                            )} - ${moment(toDate.second).format(
                              'YYYY-MM-DD'
                            )}?`}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {errors.dateError && (
                <p className="error-datepicker">{t('dateError')} </p>
              )}
              {errors.dateRangeNotMatch && (
                <p className="error-datepicker">{t('dateRangeNotMatch')} </p>
              )}
              {errors.dateRangesIntersect && (
                <p className="error-datepicker">{t('dateRangesIntersect')} </p>
              )}
              {errors.mealsNotSelect && (
                <p className="error-datepicker">{t('mealsNotSelect')} </p>
              )}
              {errors.dateRangeTooBig && (
                <p className="error-datepicker">{t('dateRangeTooBig')} </p>
              )}
            </div>
          </DialogContent>
          <DialogActions className="flex justify-content-center mb-3">
            {activeStep !== 0 && (
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className="cancel-button"
              >
                {t('back')}
              </Button>
            )}
            {activeStep !== 2 && (
              <Button
                onClick={handleNext}
                disabled={
                  activeStep === steps.length - 1 ||
                  errors.dateError ||
                  errors.mealsNotSelect ||
                  errors.dateRangeTooBig
                }
                className="confirm-button"
              >
                {t('next')}
              </Button>
            )}
            {activeStep === 2 && (
              <Button onClick={handleSubmit} className="confirm-button">
                {t('copy')}
              </Button>
            )}
          </DialogActions>
        </MuiDialog>

        <MuiDialog
          open={confirmDialog}
          disableEnforceFocus
          onClose={() => setConfirmDialog(false)}
          sx={{ padding: '20px' }}
        >
          <DialogContent sx={{ padding: '20px', maxWidth: '600px' }}>
            <DialogContentText
              id="alert-dialog-description"
              sx={{
                position: 'relative',
                maxWidth: '100%',
                margin: '0 0 0.4em',
                padding: 0,
                color: '#595959',
                fontSize: '1.6em',
                lineHeight: '1.2',
                textAlign: 'center',
                textTransform: 'none',
                wordWrap: 'break-word',
                padding: '0 29px',
              }}
            >
              {t('copyMealStarted')}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="flex justify-content-center mb-1">
            <Button
              onClick={() => setConfirmDialog(false)}
              className="confirm-button"
            >
              {t('confirm')}
            </Button>
          </DialogActions>
        </MuiDialog>
      </div>
    </div>
  );
};

const ViewMore = ({ e, handleViewMore, MealsCallBacks }) => {
  return (
    <div className="view-more-btn">
      {/* <img src={next} alt="next" onClick={() => handleViewMore(e.data)} /> */}
      <img
        src={next}
        alt="next"
        onClick={() => MealsCallBacks.moreInfo(e.data)}
      />
    </div>
  );
};
const RealizedReservations = ({ e }) => {
  return (
    <p className="p-mb-0">
      {e?.data?.mealRealized} (
      {e?.data?.mealInventory > 0
        ? Number.parseInt(
            (e?.data?.mealRealized / e?.data?.mealInventory) * 100
          )
        : 0}
      %)
    </p>
  );
};
const ReservationsInventory = ({ e }) => {
  return (
    <p className="p-mb-0">
      {e?.data?.mealOrdered} (
      {e?.data?.mealInventory > 0
        ? Number.parseInt((e?.data?.mealOrdered / e?.data?.mealInventory) * 100)
        : 0}
      %)
    </p>
  );
};

const RatingStar = ({ e }) => {
  return (
    <section className={'section2'}>
      <div
        className={`rating ${
          e?.data?.mealNumOfRaters > 0 ? '' : 'disable-rating'
        }`}
        onClick={() => handleRatingPage(e.data)}
      >
        <StaticStars
          numOfRaters={e?.data?.mealNumOfRaters || 0}
          rating={e?.data?.mealRating || -1}
        />
      </div>
    </section>
  );
};

const ActionButton = ({
  e,
  t,
  MealsCallBacks,
  isFuture,
  MealTypesMap,
  dates,
}) => {
  const handleDeleteClick = async () => {
    if (e.data.mealOrdered > 0) {
      Alert({ error: { content: t('cantDeleteMealWithOrders') } });
      return;
    }
    Dialog({
      title: t('removeMealTitle'),
      text: `${t('areYouSureYouWantToRemoveMeal')} ${e.data.mealDate} - ${
        e.data.mealType
      } `,
      buttons: {
        confirmButtonText: t('removeMealBtnLabel'),
        cancelButtonText: t('back'),
      },
      onConfirm: async () => await MealsCallBacks.delete(e.data),
    });
  };

  return (
    <div className="view-more-btn">
      {/* <img src={next} alt="next" onClick={() => handleViewMore(e.data)} /> */}
      <img
        src={next}
        alt="next"
        onClick={() => MealsCallBacks.moreInfo(e.data)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  kitchen: state.kitchens.current,
  MealTypes: state.app.enums.MealType,
  MealStatuses: state.app.enums.MealStatus,
  MealDishStatuses: state.app.enums.MealDishStatus,
  Languages: state.app.enums.Languages,
  currentLanguage: state.languages.current,
  meals: state.app.filterMeals,
  dataTableData: state.app.dataTableData,
  mealsTotal: state.app.mealsTotal,
});
const mapDispatchToProps = {
  setLayoutOptions,
  useLoader,
  useFetchingLoader,
  currentMealsData,
  setOpenCopyMealPopup,
  setISuccessCopyMealPopup,
};
export default connect(mapStateToProps, mapDispatchToProps)(MealManagement);
